<ng-container *ngIf="config">
    <div class="form-row--input form-row">
        <div class="form-row__label">{{ ".ColorSteps" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <lg-dropdown [definition]="_stepsDropdownDef" [(current)]="config.steps"></lg-dropdown>
        </div>
    </div>
    <ng-container *ngIf="_ranges">
        <div *ngFor="let range of _ranges" class="form-row--input form-row range">
            <div class="form-row__label">{{ range.name }}:</div>
            <div class="form-row__controls range__controls">
                <lg-dropdown
                    class="range__controls__type"
                    [definition]="
                        range.key === 'average'
                            ? _rangeTypeAverageDropdownDef
                            : _rangeTypeDropdownDef
                    "
                    [(current)]="range.config.type"
                ></lg-dropdown>
                <input
                    class="input range__controls__value"
                    [style.visibility]="range.config.type === 'manual' ? 'visible' : 'hidden'"
                    type="number"
                    [(ngModel)]="range.config.value"
                />
                <lg-dropdown
                    class="range__controls__color"
                    [style.visibility]="range.config.type === 'disabled' ? 'hidden' : 'visible'"
                    [definition]="_rangeColorDropdownDef"
                    [(current)]="range.config.color"
                    [hideSearch]="true"
                ></lg-dropdown>
            </div>
        </div>
    </ng-container>
</ng-container>
