import * as _ from "lodash-es";
import { Inject, Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { IComboFilter2WrappedId, LgFilterSetService } from "@logex/framework/lg-filterset";
import { LgPromptDialog } from "@logex/framework/ui-core";

import { FlexDataClientService } from "../flex-data-client/flex-data-client.service";

import { FieldInfo } from "../../types";
import { FlexibleDatasetDataArgumentsGetter } from "../flexible-dataset";
import { FlexibleFilterFactoryCreatorBase } from "./flexible-filter-factory-creator-base";
import { Observable } from "rxjs";

// ---------------------------------------------------------------------------------------------
@Injectable()
export class FlexibleFilterFactory {
    constructor(
        private _filterSetService: LgFilterSetService,
        private _promptDialog: LgPromptDialog,
        private _lgTranslate: LgTranslateService,
        @Inject(LG_APP_DEFINITIONS) private _appDefinitions: IDefinitions<any>,
        private _flexDataClient: FlexDataClientService
    ) {
        // empty
    }

    define(): FlexibleFilterFactoryCreator {
        return new FlexibleFilterFactoryCreator(
            this._filterSetService,
            this._promptDialog,
            this._lgTranslate,
            this._appDefinitions,
            this._flexDataClient
        );
    }
}

export class FlexibleFilterFactoryCreator extends FlexibleFilterFactoryCreatorBase {
    constructor(
        _filterSetService: LgFilterSetService,
        _promptDialog: LgPromptDialog,
        _lgTranslate: LgTranslateService,
        _appDefinitions: IDefinitions<any>,
        _flexDataClient: FlexDataClientService
    ) {
        super(_filterSetService, _promptDialog, _lgTranslate, _appDefinitions, _flexDataClient);
    }

    createFilterDefinitionSourceFn<T extends string | number>(
        field: FieldInfo
    ): () => Observable<Array<IComboFilter2WrappedId<T>>> {
        let argsOverride: FlexibleDatasetDataArgumentsGetter;

        return () => {
            argsOverride = argsOverride ?? {
                ...this._omitFilters(this._flexDataClient.dataset.dataArguments, field.field),
                limit: () => undefined
            };

            return this._flexDataClient.dataset.load([field.field], argsOverride).pipe(
                catchError(err => {
                    this._onServerFailure(err);
                    throw err;
                }),
                map(data => _.map(data?.data, field.field))
            );
        };
    }
}
