import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Injectable,
    QueryList,
    signal,
    ViewChildren,
    ViewEncapsulation
} from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LayoutManagementStoreService } from "./services/layout-management-store.service";
import {
    getDialogFactoryBase,
    IDialogComponent,
    LgDialogFactory,
    LgDialogRef,
    LgPromptDialog
} from "@logex/framework/ui-core";
import { mixins } from "@logex/mixin-flavors";
import { DialogMixin, HandleErrorsMixin, ModalResultDialogMixin } from "@logex/mixins";
import { toObservable } from "@angular/core/rxjs-interop";
import { catchError, map } from "rxjs/operators";
import { CdkDragDrop, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { asapScheduler, BehaviorSubject, take } from "rxjs";
import { LayoutManagementGatewayAdapterService } from "./services/layout-management-gateway-adapter.service";
import { LayoutGroup, LayoutItem } from "./layout-management.types";

export interface LayoutManagementDialogArguments {
    page: string;
}

export interface LayoutManagementDialogResponse {
    isLayoutsSaved: boolean;
}

export interface LayoutManagementDialogComponent
    extends DialogMixin<LayoutManagementDialogComponent>,
        ModalResultDialogMixin<LayoutManagementDialogArguments, LayoutManagementDialogResponse>,
        HandleErrorsMixin {}

const ROOT_GROUP_ID = "root-list";

@Component({
    selector: "lgflex-layout-management-dialog",
    templateUrl: "./layout-management-dialog.component.html",
    styleUrls: ["./layout-management-dialog.component.scss"],
    providers: [
        ...useTranslationNamespace("_Flexible._LayoutCatalogManagementDialog"),
        LayoutManagementStoreService,
        LayoutManagementGatewayAdapterService
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush
})
@mixins(DialogMixin, HandleErrorsMixin, ModalResultDialogMixin)
export class LayoutManagementDialogComponent
    implements IDialogComponent<LayoutManagementDialogComponent>
{
    readonly _promptDialog = inject(LgPromptDialog);
    readonly _dialogRef = inject(LgDialogRef<LayoutManagementDialogComponent>);
    readonly _lgTranslate = inject(LgTranslateService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _layoutManagementService = inject(LayoutManagementStoreService);
    private readonly _layoutManagementApiService = inject(LayoutManagementGatewayAdapterService);

    private _progressSubject = new BehaviorSubject(false);
    public _args: LayoutManagementDialogArguments;
    private readonly _isValid$ = toObservable(this._layoutManagementService.isValid);

    protected readonly _layoutGroupsAndItems = this._layoutManagementService.layoutsGroups;
    protected readonly _rootGroupId = ROOT_GROUP_ID;
    protected _isLoading = signal(false);

    // Dialog configuration
    // ----------------------------------------------------------------------------------
    _dialogClass = "lg-dialog lg-dialog--6col";
    _title = this._lgTranslate.translate(".LayoutManagementTitle");
    _dialogButtons = [
        {
            textLc: this._lgTranslate.translate(".Save"),
            onClick: () => {
                this._saveLayouts();
            },
            class: "button--primary",
            isDisabled$: this._isValid$.pipe(map(isValid => !isValid)),
            progress$: this._progressSubject
                .asObservable()
                .pipe(
                    map(
                        isProgressEnabled =>
                            isProgressEnabled && this._lgTranslate.translate(".Saving")
                    )
                )
        },
        {
            textLc: this._lgTranslate.translate(".Cancel"),
            onClick: () => {
                this._dialogRef.close();
                this._resolve({ isLayoutsSaved: false });
            }
        }
    ];

    // ----------------------------------------------------------------------------------

    constructor() {
        this._initMixins();
    }

    async show(args: LayoutManagementDialogArguments): Promise<LayoutManagementDialogResponse> {
        this._args = args;
        this._isLoading.set(true);

        this._layoutManagementApiService
            .loadSharedLayouts(this._args.page)
            .pipe(
                catchError((error: any) => {
                    this._onServerFailure(error);
                    this._dialogRef.close();
                    throw Error(error);
                }),
                take(1)
            )
            .subscribe(layoutGroupItems => {
                this._layoutManagementService.setLayouts(layoutGroupItems);
                this._isLoading.set(false);
                this._updateDropListIds();
            });

        return null;
    }

    protected _isLayoutGroup(layout: LayoutGroup | LayoutItem): layout is LayoutGroup {
        return (layout as LayoutGroup).layouts !== undefined;
    }

    protected _isLayoutItem(layout: LayoutGroup | LayoutItem): layout is LayoutItem {
        return (
            (layout as LayoutItem).libraries !== undefined &&
            (layout as LayoutItem).dataSources !== undefined
        );
    }

    protected _addGroup(): void {
        this._layoutManagementService.addGroup();
        this._updateDropListIds();
    }

    protected _groupNameChange(name: string, id: number): void {
        this._layoutManagementService.changeGroupName(name, id);
    }

    protected _changeShowInNavigation(value: boolean, id: number): void {
        this._layoutManagementService.changeShowInNavigation(value, id);
    }

    async _removeGroup(id: number): Promise<void> {
        if (await this._confirmRemoveGroup()) {
            this._layoutManagementService.removeGroup(id);
            this._updateDropListIds();
        }
    }

    private async _confirmRemoveGroup(): Promise<boolean> {
        const dialogResult = await this._promptDialog.confirm(
            this._lgTranslate.translate(".RemoveGroupDialogTitle"),
            this._lgTranslate.translate(".RemoveGroupDialogBody"),
            {
                buttons: [
                    {
                        id: "delete",
                        name: this._lgTranslate.translate(".RemoveGroupDialogDelete"),
                        class: "button--primary",
                        isConfirmAction: true
                    },
                    {
                        id: "cancel",
                        name: this._lgTranslate.translate(".RemoveGroupDialogCancel"),
                        isCancelAction: true
                    }
                ],
                columns: 2
            }
        );

        return dialogResult === "delete";
    }

    private _saveLayouts(): void {
        this._progressSubject.next(true);
        this._layoutManagementApiService
            .saveSharedLayouts(
                this._args.page,
                this._layoutManagementService.flatLayouts(),
                this._layoutManagementService.deletedGroupIds()
            )
            .pipe(
                catchError((error: any) => {
                    this._onServerFailure(error);
                    this._dialogRef.close();
                    throw Error(error);
                }),
                take(1)
            )
            .subscribe(() => {
                this._progressSubject.next(false);
                this._dialogRef.close();
                this._resolve({ isLayoutsSaved: true });
            });
    }

    protected _drop(event: CdkDragDrop<any>) {
        const previousContainerId =
            event.previousContainer.id !== this._rootGroupId
                ? Number(event.previousContainer.id)
                : this._rootGroupId;
        const containerId =
            event.container.id !== this._rootGroupId
                ? Number(event.container.id)
                : this._rootGroupId;

        const previousIndex = event.previousIndex;
        const currentIndex = event.currentIndex;

        const itemId = event.item.data;

        // prevent drop group into group
        const item = event.previousContainer.data[previousIndex];
        const isGroupDragging = !item.libraries && !item.dataSources;
        if (
            previousContainerId === this._rootGroupId &&
            containerId !== this._rootGroupId &&
            isGroupDragging
        ) {
            return;
        }

        let data = [];
        if (containerId === this._rootGroupId && previousContainerId === this._rootGroupId) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            data = event.container.data.reduce((acc, layout) => {
                return [...acc, layout, ...(layout.layouts || [])];
            }, []);
        } else {
            data = this._layoutManagementService.flatLayouts();

            if (previousContainerId === this._rootGroupId) {
                const itemToAdd = data.find(layout => layout.id === itemId);
                itemToAdd.groupId = containerId;

                data = data.filter(layout => layout.id !== itemId);
                const flatItemIndex =
                    data.findIndex(layout => layout.id === containerId) + currentIndex;
                data.splice(flatItemIndex + 1, 0, itemToAdd);
            } else if (containerId === this._rootGroupId) {
                const itemToAdd = data.find(layout => layout.id === itemId);
                itemToAdd.groupId = undefined;

                data = event.container.data.map(item => {
                    if (item.id === Number(event.previousContainer.id)) {
                        return {
                            ...item,
                            layouts: item.layouts.filter(layout => layout.id !== itemId)
                        };
                    }
                    return item;
                });
                data.splice(currentIndex, 0, itemToAdd);

                data = data.reduce(
                    (acc, layout) => [...acc, layout, ...(layout.layouts || [])],
                    []
                );
            } else {
                const itemToAdd = data.find(layout => layout.id === itemId);
                itemToAdd.groupId = containerId;

                data = data.filter(layout => layout.id !== itemId);
                const flatItemIndex =
                    data.findIndex(layout => layout.id === containerId) + currentIndex;
                data.splice(flatItemIndex + 1, 0, itemToAdd);
            }
        }
        const flatLayouts = data.map((layout, index) => ({ ...layout, sortOrder: index }));
        this._layoutManagementService.setLayouts(flatLayouts);
    }

    protected _connectedToGroupIds(): string[] {
        const groupsIds = this._layoutManagementService
            .flatLayouts()
            .filter(layout => !layout.dataSources && !layout.libraries)
            .map(group => `${group.id}`);
        return [ROOT_GROUP_ID, ...groupsIds];
    }

    // Angular CDK Drag and Drop issue workaround: https://github.com/angular/components/issues/16671
    // ----------------------------------------------------------------------------------------------
    @ViewChildren(CdkDropList)
    private _dlq: QueryList<CdkDropList>;

    private _dls: CdkDropList[] = [];

    private _updateDropListIds() {
        this._changeDetectorRef.detectChanges();

        let ldls: CdkDropList[] = [];

        this._dlq.forEach(dl => {
            ldls.push(dl);
        });

        ldls = ldls.reverse();

        asapScheduler.schedule(() => {
            this._dls = ldls;
            const siblings = this._dls.map(dl => dl?._dropListRef);
            this._dlq.forEach(dl => {
                dl._dropListRef._getSiblingContainerFromPosition = (item, x, y) =>
                    siblings.find(sibling => sibling._canReceive(item, x, y));
            });
        });
    }

    // -------------------------------------------------------------------------------------------------
}

@Injectable()
export class LayoutManagementDialog extends getDialogFactoryBase(
    LayoutManagementDialogComponent,
    "show"
) {
    constructor() {
        const _factory = inject(LgDialogFactory);
        super(_factory);
    }
}
