import * as _ from "lodash-es";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import {
    IAppSession,
    IDefinitions,
    LG_APP_DEFINITIONS,
    LG_APP_SESSION
} from "@logex/framework/lg-application";
import { IComboFilter2WrappedId, LgFilterSetService } from "@logex/framework/lg-filterset";
import { LgPromptDialog } from "@logex/framework/ui-core";

import { FlexDataClientService } from "../flex-data-client/flex-data-client.service";

import { FieldInfo } from "../../types";
import { FlexibleFilterFactoryCreatorBase } from "./flexible-filter-factory-creator-base";
import { isDefinitionTypeField } from "../../utilities/isDefinitionTypeField";

// ---------------------------------------------------------------------------------------------
@Injectable()
export class FlexibleLayoutFilterFactory {
    constructor(
        private _filterSetService: LgFilterSetService,
        private _promptDialog: LgPromptDialog,
        private _lgTranslate: LgTranslateService,
        @Inject(LG_APP_DEFINITIONS) private _appDefinitions: IDefinitions<any>,
        private _flexDataClient: FlexDataClientService,
        @Inject(LG_APP_SESSION) private _session: IAppSession
    ) {
        // empty
    }

    define(): FlexibleLayoutFilterFactoryCreator {
        return new FlexibleLayoutFilterFactoryCreator(
            this._filterSetService,
            this._promptDialog,
            this._lgTranslate,
            this._appDefinitions,
            this._flexDataClient,
            this._session
        );
    }
}

export class FlexibleLayoutFilterFactoryCreator extends FlexibleFilterFactoryCreatorBase {
    constructor(
        _filterSetService: LgFilterSetService,
        _promptDialog: LgPromptDialog,
        _lgTranslate: LgTranslateService,
        _appDefinitions: IDefinitions<any>,
        _flexDataClient: FlexDataClientService,
        private _session: IAppSession
    ) {
        super(_filterSetService, _promptDialog, _lgTranslate, _appDefinitions, _flexDataClient);
    }

    createFilterDefinitionSourceFn<T extends string | number>(
        field: FieldInfo
    ): () => Observable<Array<IComboFilter2WrappedId<T>>> {
        return () => {
            if (isDefinitionTypeField(field)) {
                const definitionSection = this._appDefinitions.getSection(field.type);
                if (!definitionSection) throw Error("Unknown definition section");
                const codeField = definitionSection.codeField;
                return of(_.map(definitionSection.data, codeField));
            }

            return this._flexDataClient.dataset.load([field.field]).pipe(
                catchError(err => {
                    this._onServerFailure(err);
                    throw err;
                }),
                map(data => _.map(data?.data, field.field))
            );
        };
    }
}
