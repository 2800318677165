<ng-template [ngIf]="vm$ | async" let-vm>
    <div
        *ngIf="vm.hasColumns !== null"
        class="form-section__content"
        [ngClass]="{
            'form-section__content--row': vm.hasColumns === true,
            'form-section__content--column': vm.hasColumns === false
        }"
    >
        <ng-content />
    </div>
</ng-template>

<div *ngIf="hideDivider === false" class="divider"></div>
