<ng-container>
    <lgflex-form-section [hideDivider]="false">
        <lgflex-form-section-column>
            <lgflex-form-row [label]="'.Title' | lgTranslate">
                <input
                    (ngModelChange)="_onTablePropertyChange($event, 'name')"
                    [ngModel]="_level().name"
                    class="input input--wide"
                    type="text"
                />
            </lgflex-form-row>
            <lgflex-form-row [label]="'.DisplayMode' | lgTranslate">
                <lg-dropdown
                    (currentChange)="_onTablePropertyChange($event, 'type')"
                    [current]="_level().type"
                    [definition]="_tableTypeDropdown"
                ></lg-dropdown>
            </lgflex-form-row>
            <lgflex-form-row [label]="'.Extra' | lgTranslate">
                <label>
                    <input
                        (ngModelChange)="_onTablePropertyChange($event, 'ignoreOwnFilters')"
                        [ngModel]="_level().ignoreOwnFilters"
                        lgStyledCheckbox
                        type="checkbox"
                    />
                    {{ ".IgnoreOwnFilters" | lgTranslate }}
                </label>
            </lgflex-form-row>
        </lgflex-form-section-column>
    </lgflex-form-section>

    <lgflex-form-section [hideDivider]="true">
        <lgflex-form-section-header>
            {{ ".LimitRows" | lgTranslate }}
        </lgflex-form-section-header>
        <lgflex-form-row [label]="'.Rows' | lgTranslate">
            <lg-slider
                (ngModelChange)="_onLimitRowsSliderChange($event)"
                [ngModel]="_level().limitRows"
                [allowPlusInfinity]="true"
                [max]="100"
                [min]="1"
                [step]="1"
                [plusInfinityText]="'.MaxRows' | lgTranslate"
            ></lg-slider>
            @if (_level().limitRows === _maxLimitRows) {
                <input
                    [value]="'.MaxRows' | lgTranslate"
                    [disabled]="true"
                    width="90px"
                    class="input lgflex-level-properties-limit-row-input"
                    type="text"
                />
            } @else {
                <input
                    (ngModelChange)="_onTablePropertyChange($event, 'limitRows')"
                    [disabled]="_level().limitRows > 100"
                    [max]="100"
                    [min]="1"
                    [ngModel]="_level().limitRows"
                    width="90px"
                    class="input lgflex-level-properties-limit-row-input"
                    type="number"
                />
            }
        </lgflex-form-row>
        <lgflex-form-section-header>
            {{ ".OrderBy" | lgTranslate }}
        </lgflex-form-section-header>
        <lgflex-level-sorting-form
            (sortingAdded)="_addSorting()"
            (sortingItemDrop)="_onSortingDrop($event)"
            (sortingItemUpdated)="_orderByItemAdd()"
            (sortingRemoved)="_removeOrderBySorting($event)"
            [levelSortingItems]="_level().orderBy"
            [sortingDropdown]="orderByItemDropdown"
        >
        </lgflex-level-sorting-form>
    </lgflex-form-section>
</ng-container>
