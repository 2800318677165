import * as _ from "lodash-es";
import { chain, Dictionary } from "lodash";
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output
} from "@angular/core";
import { DatePipe } from "@angular/common";

import { LgFormatTypePipe, LgMarkSymbolsPipe } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LgConsole, LgFormatterFactoryService } from "@logex/framework/core";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { IPivotTableLevelHeader } from "@logex/framework/lg-pivot-table";

import { LgPivotInstance, LogexPivotService } from "@logex/framework/lg-pivot";

import {
    ColumnAndFieldInfo,
    DrilldownKeyItem,
    FdpDifferenceColumnChangeEvent,
    FdpLevel,
    FdpSubLevel,
    FieldInfo,
    filterColumnId,
    FlexibleDrilldownPivotTableState,
    ICON_COLUMN_WIDTH,
    levelFieldColumnId,
    PivotTableColumn,
    PivotTableLevel
} from "../../types";

import { FlexibleDrilldownBaseComponent } from "../base/flexible-drilldown-base/flexible-drilldown-base.component";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";

// ----------------------------------------------------------------------------------
@Component({
    selector: "lgflex-flexible-drilldown-pivot-table",
    templateUrl: "./flexible-drilldown-pivot-table.component.html",
    host: {
        class: "flexcol"
    }
})
export class FlexibleDrilldownPivotTableComponent
    extends FlexibleDrilldownBaseComponent
    implements OnInit, OnChanges, AfterViewChecked
{
    constructor(
        _lgTranslate: LgTranslateService,
        _lgConsole: LgConsole,
        _formatter: LgFormatTypePipe,
        _lgMarkSymbols: LgMarkSymbolsPipe,
        _fmtDate: DatePipe,
        @Inject(LG_APP_DEFINITIONS) _definitions: IDefinitions<any>,
        _pivotService: LogexPivotService,
        _formatterFactory: LgFormatterFactoryService,
        _changeDetectorRef: ChangeDetectorRef
    ) {
        super(
            _lgTranslate,
            _lgConsole,
            _formatter,
            _lgMarkSymbols,
            _fmtDate,
            _definitions,
            _pivotService,
            _formatterFactory,
            _changeDetectorRef
        );
        this._lgConsole = this._lgConsole.withSource("FlexibleDrilldownPivotTable");
        this._prepareVisibilityUpdates(this.filters, this.pivots, this.levels);
    }

    @Input() override scheme!: FieldInfo[];
    @Input() override levels!: string[][];
    @Input() levelProperties: Dictionary<PivotTableLevel> | undefined;

    @Input() override columns!: PivotTableColumn[];

    @Input() standalone = false;

    @Input() override set pageReferences(value: PageReferencesService | undefined) {
        // Setter is empty - changes are handled in ngOnChanges()
    }

    override get pageReferences(): PageReferencesService | undefined {
        return this._pageReferences;
    }

    @Input() override set pivots(pivots: LgPivotInstance[]) {
        // Setter is empty because pivot change is handled by ngOnChanges() method
    }

    override get pivots(): LgPivotInstance[] {
        return this._pivots;
    }

    @Input() override filters?: LgFilterSet | undefined;

    @Input() widgetTypes?: WidgetTypesRegistry | undefined;

    @Input() initialState: FlexibleDrilldownPivotTableState | null = null;

    @Output() readonly differenceColumnChange = new EventEmitter<FdpDifferenceColumnChangeEvent>();

    @Output() override readonly drillChange = new EventEmitter<DrilldownKeyItem[][]>();

    _levels: FdpLevel[] = [];
    _visibleLevels: FdpLevel[] = [];
    protected _maxColumnValues: Record<string, number> = {};

    _doBuildPivotModel(): void {
        if (this.widgetTypes === undefined) {
            throw Error("WidgetTypes can't be undefined.");
        }

        const columnsAndFields: ColumnAndFieldInfo[] = chain(this.columns)
            .map((x, i) => ({
                column: x,
                field: this._getField(x),
                index: i
            }))
            .value();

        this._validateColumns(columnsAndFields);

        // Prepare value cells that are reused in all pivot rows except for header row
        const valueCells = [
            ..._.map(columnsAndFields, x => this._getValueCell(x, this.widgetTypes!))
        ];

        // Add levels definitions
        this._levels = [];

        if (this.initialState) {
            this._setStateDo(this.initialState, this.levels, this.drillChange);
            this.initialState = null;
        }

        _.each(this.levels, (fields, levelIdx) => {
            const isLastLevel = levelIdx === this.levels.length - 1;
            const levelHeaders: IPivotTableLevelHeader[] = [];

            const levelPivot = this._pivots[levelIdx];
            if (levelPivot == null) throw Error(`Pivot for level ${levelIdx} is not supplied`);

            const levelRow: FdpLevel = {
                level: levelIdx,
                pivot: levelPivot,
                levelHeaders,
                columnDefinition: [],
                subLevels: [],
                maxVisibleSubLevel: 0
            };

            this._levels.push(levelRow);

            // Add header and footer definitions
            if (levelIdx === 0) {
                this._headerColumnDef = [
                    {
                        id: levelFieldColumnId,
                        columnCls: "crop",
                        width: "*"
                    }
                ];

                this._headerRow = [
                    {
                        id: levelFieldColumnId,
                        type: "levels",
                        levelHeaders
                    }
                ];

                this._totalsColumnDef = [
                    {
                        id: levelFieldColumnId,
                        columnCls: "crop",
                        width: "*"
                    }
                ];

                this._totalsRow = [
                    {
                        id: levelFieldColumnId,
                        type: "text",
                        value: this._lgTranslate.translate("_Flexible._.Total")
                    }
                ];

                // Filter icon
                this._addFilterIconColumn(this.filters);
                this._addToColumnsDef(columnsAndFields);
            }

            const levelFields = _.map(fields, x => this._getFieldByName(x));

            _.each(levelFields, (levelField, fieldIdx) => {
                const subLevelId = `row${fieldIdx + 1}`;
                const isLastSubLevel = fieldIdx === fields.length - 1;
                const isVeryLast = isLastLevel && isLastSubLevel;

                const levelColumnsDefinition = [...this._headerColumnDef];
                const subLevelRow: FdpSubLevel = {
                    levelId: subLevelId,
                    openOnClick: !isLastSubLevel,
                    isVeryLast,
                    cells: []
                };
                levelRow.subLevels.push(subLevelRow);

                if (!isLastSubLevel) {
                    levelColumnsDefinition.unshift({ id: "expand", type: "expand" });
                    subLevelRow.cells.unshift({ id: "expand", type: "expand" });
                }

                for (let j = fieldIdx; j >= 1; j--) {
                    const cellId = `empty${j}`;
                    levelColumnsDefinition.unshift({ id: cellId, type: "empty" });
                    subLevelRow.cells.unshift({ id: cellId, type: "empty" });
                }

                // Add the level to the columns definition
                levelRow.columnDefinition.push({
                    id: subLevelId,
                    columns: levelColumnsDefinition
                });

                // Add this level to the first column header
                levelHeaders.push({
                    header:
                        this.levelProperties?.[levelField.field]?.title ??
                        levelField.name ??
                        (levelField.nameLc ? undefined : ""),
                    headerLc: levelField.nameLc ?? undefined,
                    orderBy: levelField.field
                });

                // Add cells to the model of the current row
                subLevelRow.cells.push({
                    ...this._getDimensionCell(levelField, this.levelProperties),
                    id: levelFieldColumnId
                });

                if (this.filters !== undefined) {
                    subLevelRow.cells.push({
                        id: filterColumnId,
                        type: "filter",
                        fieldName: levelField.field
                    });
                }

                subLevelRow.cells.push(...valueCells);
            });
        });

        this._updateVisibleLevels();
    }

    _drillDown(levelIdx: number, subLevelIdx: number, row: { [K: string]: unknown }): void {
        const levelDef = this._levels[levelIdx];
        const subLevelDef = levelDef.subLevels[subLevelIdx];

        // Drilling up
        if (this._selectedKeys[levelIdx] !== undefined) {
            this._selectedKeys = this._selectedKeys.splice(0, levelIdx);
        } else {
            if (subLevelDef.openOnClick || subLevelDef.isVeryLast) return;

            this._selectedKeys[levelIdx] = [];
            for (const key of this.levels[levelIdx]) {
                this._selectedKeys[levelIdx].push({
                    level: subLevelIdx, // is this value actually correct / useful? I would expect it to match the index of the loop above
                    fieldName: key,
                    value: row[key]
                });
            }
        }

        this._updateVisibleLevels();
        this.drillChange.next(this._selectedKeys);
    }

    _drilldownGoUp(): void {
        if (this._selectedKeys.length <= 0) return;
        this._drillDown(this._selectedKeys.length - 1, 0, {});
    }

    _onMaxVisibleSubLevelChange(level: number, value: number): void {
        this._levels[level].maxVisibleSubLevel = value;
    }

    protected _updateVisibleLevels(): void {
        this._maxVisibleLevelIdx = this._selectedKeys.length;
        this._visibleLevels = _.take(this._levels, this._maxVisibleLevelIdx + 1);
        this._maxVisibleLevel = _.last(this._visibleLevels);
    }

    private _addFilterIconColumn(filters: LgFilterSet | undefined): void {
        if (filters !== undefined) {
            this._headerColumnDef.push({
                id: filterColumnId,
                type: "icons",
                width: ICON_COLUMN_WIDTH
            });

            this._headerRow.push({
                type: "icons",
                id: filterColumnId
            });

            this._totalsColumnDef.push({
                id: filterColumnId,
                type: "icons",
                width: ICON_COLUMN_WIDTH
            });

            this._totalsRow.push({
                id: filterColumnId,
                type: "filter",
                fieldName: undefined
            });
        }
    }
}
