<div class="lgflex-layout__container">
    @if (_isLoading()) {
        <div class="lgflex-layout__loader">
            <lg-loader-tab></lg-loader-tab>
        </div>
    } @else {
        <div class="lgflex-layout__top-panel">
            <lg-button
                text="{{ '.AddGroup' | lgTranslate }}"
                icon="icon-add"
                (click)="_addGroup(); scrollContainer.scrollTo(0, 0)"
            ></lg-button>
        </div>
        <div class="lgflex-layout-table" cdkDropListGroup>
            <div class="lgflex-layout-table__header">
                <div
                    class="lgflex-layout-table__header-item lgflex-layout-table__header-item--padding-left"
                >
                    {{ ".LayoutName" | lgTranslate }}
                </div>
                <div class="lgflex-layout-table__header-item">{{ ".Library" | lgTranslate }}</div>
                <div class="lgflex-layout-table__header-item">
                    {{ ".DataSource" | lgTranslate }}
                </div>
                <div
                    class="lgflex-layout-table__header-item lgflex-layout-table__header-item--align-end lgflex-layout-table__header-item--padding-right"
                >
                    {{ ".ShowInNavigation" | lgTranslate }}
                </div>
            </div>
            <div
                class="lgflex-layout-table__body"
                id="{{ _rootGroupId }}"
                #scrollContainer
                lgScrollable
                cdkDropList
                [cdkDropListData]="_layoutGroupsAndItems()"
                [cdkDropListConnectedTo]="_connectedToGroupIds()"
                (cdkDropListDropped)="_drop($event)"
            >
                @for (item of _layoutGroupsAndItems(); track item.id) {
                    @if (_isLayoutGroup(item)) {
                        <div class="lgflex-group" cdkDrag [cdkDragData]="item.id">
                            <div class="lgflex-group__row">
                                <div class="lgflex-group-name">
                                    <lgflex-group-name-icon-warning
                                        #groupNameIconWarning
                                        [group]="item"
                                        [layouts]="_layoutGroupsAndItems()"
                                    />
                                    @if (!groupNameIconWarning.warningMessage()) {
                                        <lg-icon
                                            icon="icon-draggable"
                                            class="lgflex-icon lgflex-icon__draggable lgflex-hover-only"
                                        />
                                    }
                                    <lg-editable-grid-text
                                        class="lgflex-group-name__input table__row table__column lgflex-group-name__input--padding-left"
                                        placeholder="{{ '.GroupName' | lgTranslate }}"
                                        [ngClass]="{
                                            'lgflex-group-name__input--warning':
                                                groupNameIconWarning.warningMessage()
                                        }"
                                        [confirmMessageDisabled]="true"
                                        [nullOrEmptyValueDisabled]="true"
                                        [model]="item.name"
                                        (modelChange)="_groupNameChange($event, item.id)"
                                    >
                                        <lg-icon
                                            class="lgflex-icon lgflex-icon__delete lgflex-hover-only"
                                            icon="icon-delete"
                                            [lgTooltip]="'.DeleteGroup' | lgTranslate"
                                            (click)="_removeGroup(item.id)"
                                        >
                                        </lg-icon>
                                    </lg-editable-grid-text>
                                </div>
                                <div
                                    class="lgflex-group__row-item lgflex-group__row-item--align-end lgflex-group__row-item--padding-right"
                                >
                                    <lg-selection-checkbox
                                        [state]="item.layouts | lgflexGroupSelection"
                                        (checked)="_changeShowInNavigation($event, item.id)"
                                    >
                                    </lg-selection-checkbox>
                                </div>
                            </div>
                            <div
                                class="lgflex-group__container"
                                id="{{ item.id }}"
                                cdkDropList
                                [cdkDropListData]="item.layouts"
                                [cdkDropListConnectedTo]="_connectedToGroupIds()"
                                (cdkDropListDropped)="_drop($event)"
                            >
                                @if (item.layouts.length === 0) {
                                    <div class="lgflex-layout lgflex-layout--empty">
                                        <div
                                            class="lgflex-layout__item lgflex-layout__item--padding-left"
                                        >
                                            {{ ".DragAndDropLayoutsHere" | lgTranslate }}...
                                        </div>
                                    </div>
                                }
                                @for (layout of item.layouts; track layout.id) {
                                    <div class="lgflex-layout" cdkDrag [cdkDragData]="layout.id">
                                        <lg-icon
                                            icon="icon-draggable"
                                            class="lgflex-icon lgflex-icon__draggable lgflex-hover-only"
                                        ></lg-icon>
                                        <div
                                            class="lgflex-layout__item lgflex-layout__item--padding-left"
                                        >
                                            {{ layout.name }}
                                        </div>
                                        <div
                                            class="lgflex-layout__item"
                                            [lgTooltip]="layout.libraries"
                                        >
                                            {{ layout.libraries }}
                                        </div>
                                        <div
                                            class="lgflex-layout__item"
                                            [lgTooltip]="layout.dataSources | lgflexDataSourceNames"
                                        >
                                            {{ layout.dataSources | lgflexDataSourceNames }}
                                        </div>
                                        <div
                                            class="lgflex-layout__item lgflex-layout__item--align-end lgflex-layout__item--padding-right"
                                        >
                                            <lg-selection-checkbox
                                                [state]="layout.showInNavigation"
                                                (checked)="
                                                    _changeShowInNavigation($event, layout.id)
                                                "
                                            >
                                            </lg-selection-checkbox>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    @if (_isLayoutItem(item)) {
                        <div class="lgflex-layout" cdkDrag [cdkDragData]="item.id">
                            <lg-icon
                                icon="icon-draggable"
                                class="lgflex-icon lgflex-icon__draggable lgflex-hover-only"
                            ></lg-icon>
                            <div class="lgflex-layout__item lgflex-layout__item--padding-left">
                                {{ item.name }}
                            </div>
                            <div class="lgflex-layout__item" [lgTooltip]="item.libraries">
                                {{ item.libraries }}
                            </div>
                            <div
                                class="lgflex-layout__item"
                                [lgTooltip]="item.dataSources | lgflexDataSourceNames"
                            >
                                {{ item.dataSources | lgflexDataSourceNames }}
                            </div>
                            <div
                                class="lgflex-layout__item lgflex-layout__item--align-end lgflex-layout__item--padding-right"
                            >
                                <lg-selection-checkbox
                                    [state]="item.showInNavigation"
                                    (checked)="_changeShowInNavigation($event, item.id)"
                                >
                                </lg-selection-checkbox>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
    }
</div>
