import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    Output
} from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    IFlexiblePageAuthorization,
    LG_FLEX_PAGE_AUTHORIZATION
} from "../../../services/flexible-page-authorization/flexible-page-authorization.types";

@Component({
    selector: "lgflex-widget-configuration-section-information",
    templateUrl: "./widget-configuration-section-information.component.html",
    styleUrls: ["./widget-configuration-section-information.component.scss"],
    providers: [...useTranslationNamespace("_Flexible._PivotConfigurationDialog")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetConfigurationSectionInformationComponent {
    constructor(
        @Inject(LG_FLEX_PAGE_AUTHORIZATION)
        private _flexPageAuthorization: IFlexiblePageAuthorization
    ) {}

    @Input() widgetName: string | undefined;
    @Output() readonly widgetNameChange = new EventEmitter<string>();

    @Input() widgetDescription: string | undefined;
    @Output() readonly widgetDescriptionChange = new EventEmitter<string>();

    @Input() widgetTooltip: string | undefined;
    @Output() readonly widgetTooltipChange = new EventEmitter<string>();

    @Input() widgetTooltipLink: string | undefined;
    @Output() readonly widgetTooltipLinkChange = new EventEmitter<string>();

    _canEditWidgetTooltipLink = this._flexPageAuthorization.canEditWidgetTooltipLink();
}
