import * as _ from "lodash-es";
import { chain, Dictionary } from "lodash";
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    signal,
    ViewChild
} from "@angular/core";
import { DatePipe } from "@angular/common";

import {
    LgFormatTypePipe,
    LgMarkSymbolsPipe,
    LgObserveSizeApi,
    LgObserveSizeService
} from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LgConsole, LgFormatterFactoryService } from "@logex/framework/core";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { IPivotTableLevelHeader, LgPivotTableBodyComponent } from "@logex/framework/lg-pivot-table";

import {
    IOrderByPerLevelSpecification,
    LgPivotInstance,
    LogexPivotService
} from "@logex/framework/lg-pivot";

import {
    ColumnAndFieldInfo,
    DrilldownKeyItem,
    FdpCell,
    FdpColumnHeader,
    FdpDifferenceColumnChangeEvent,
    FdpSubLevel,
    FieldInfo,
    filterColumnId,
    FlexibleDrilldownPivotTableState,
    ICON_COLUMN_WIDTH,
    IPivotTableLevelHeaderFlatTable,
    levelFieldColumnId,
    PivotTableColumn,
    PivotTableLevel
} from "../../types";

import { FlexibleDrilldownBaseComponent } from "../base/flexible-drilldown-base/flexible-drilldown-base.component";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";

import {
    FdpSpecifiedColumnDefinition,
    FdpLevelWithType,
    HeaderRowGroup,
    HeaderRowColumn,
    PivotTableColumnOrGroup,
    PivotTableTablesConfig
} from "./pivot-table.types";
import { tap, takeUntil } from "rxjs/operators";

// ----------------------------------------------------------------------------------
@Component({
    selector: "lgflex-pivot-table",
    templateUrl: "./pivot-table.component.html",
    host: {
        class: "flexcol"
    },
    styleUrls: ["./pivot-table.scss"]
})
export class PivotTableComponent
    extends FlexibleDrilldownBaseComponent
    implements OnInit, OnChanges, AfterViewChecked
{
    constructor(
        _lgTranslate: LgTranslateService,
        _lgConsole: LgConsole,
        _formatter: LgFormatTypePipe,
        _lgMarkSymbols: LgMarkSymbolsPipe,
        _fmtDate: DatePipe,
        @Inject(LG_APP_DEFINITIONS) _definitions: IDefinitions<any>,
        _pivotService: LogexPivotService,
        _formatterFactory: LgFormatterFactoryService,
        _changeDetectorRef: ChangeDetectorRef
    ) {
        super(
            _lgTranslate,
            _lgConsole,
            _formatter,
            _lgMarkSymbols,
            _fmtDate,
            _definitions,
            _pivotService,
            _formatterFactory,
            _changeDetectorRef
        );
        this._lgConsole = this._lgConsole.withSource("FlexibleDrilldownPivotTable");
        this._prepareVisibilityUpdates(this.filters, this.pivots, this.levels);

        const _elementRef = inject(ElementRef);
        const _renderer = inject(Renderer2);
        const _observeService = inject(LgObserveSizeService);

        this._resizeObserverApi = _observeService.observe(_elementRef, _renderer);
    }

    @Input() tablesConfig: PivotTableTablesConfig[];
    @Input() override scheme!: FieldInfo[];

    @Input() columnsGroups: PivotTableColumnOrGroup[] = [];

    @Input() standalone = false;

    @Input()
    override set pageReferences(value: PageReferencesService | undefined) {
        // Setter is empty - changes are handled in ngOnChanges()
    }

    override get pageReferences(): PageReferencesService | undefined {
        return this._pageReferences;
    }

    @Input()
    override set pivots(pivots: LgPivotInstance[]) {
        // Setter is empty because pivot change is handled by ngOnChanges() method
    }

    override get pivots(): LgPivotInstance[] {
        return this._pivots;
    }

    @Input() override filters?: LgFilterSet | undefined;

    @Input() widgetTypes?: WidgetTypesRegistry | undefined;

    @Input() initialState: FlexibleDrilldownPivotTableState | null = null;

    @Input() maxColumnValues: Record<string, number>;

    @Output() readonly differenceColumnChange = new EventEmitter<FdpDifferenceColumnChangeEvent>();

    @Output() override readonly drillChange = new EventEmitter<DrilldownKeyItem[][]>();

    override levels!: string[][];
    override _headerColumnDef: FdpSpecifiedColumnDefinition[] = [];
    protected _levels: FdpLevelWithType[] = [];
    protected _visibleLevels: FdpLevelWithType[] = [];
    protected _currentColumnSorting = signal("");

    protected _pivotWrapperWidth = signal(0);
    protected _pivotBody: LgPivotTableBodyComponent;
    private _resizeObserverApi: LgObserveSizeApi;
    @ViewChild("pivotBody") set pivotBody(pivotBody: LgPivotTableBodyComponent) {
        if (pivotBody) {
            this._pivotBody = pivotBody;
        }
    }

    get pivotBody() {
        return this._pivotBody;
    }

    override ngOnInit() {
        this.levels = this.tablesConfig
            ? this.tablesConfig.map(t => t.dimensions.map(d => d.fieldId))
            : [];

        this._resizeObserverApi
            .change({
                type: "all",
                outsideZone: false
            })
            .pipe(
                tap(event => {
                    this._pivotWrapperWidth.set(event.width);
                }),
                takeUntil(this._destroyed$)
            )
            .subscribe();
    }

    override _updateVisibility() {
        const isFiltersActive = this.filters.isAnyActive();
        const currentPivot = this._pivots[this._selectedKeys.length];
        const levelConfig = this.tablesConfig[this._selectedKeys.length];

        if (!currentPivot.all) return;

        if (isFiltersActive && levelConfig.ignoreOwnFilters) {
            const { all, filtered } = currentPivot;

            all.forEach(row => (row.$disabled = !filtered.includes(row)));
            return;
        }

        currentPivot.all.forEach(row => (row.$disabled = false));
    }

    _doBuildPivotModel(): void {
        if (this.widgetTypes === undefined) {
            throw Error("WidgetTypes can't be undefined.");
        }
        const columnsWithFields = [];
        let index = -1;
        this.columnsGroups.forEach(group => {
            if ("columns" in group) {
                if (group.columns && group.columns.length > 0) {
                    group.columns.forEach(column =>
                        columnsWithFields.push({
                            column,
                            field: this._getField(column as PivotTableColumn),
                            index: ++index
                        })
                    );
                }

                return;
            }

            columnsWithFields.push({
                column: group,
                field: this._getField(group as PivotTableColumn),
                index: ++index
            });
        });

        // Prepare value cells that are reused in all pivot rows except for header row
        const valueCells = [
            ..._.map(columnsWithFields, x => this._getValueCell(x, this.widgetTypes!))
        ];

        // Add levels definitions
        this._levels = [];

        if (this.initialState) {
            this._setStateDo(this.initialState, this.levels, this.drillChange);
            this.initialState = null;
        }

        this._prepareDrilldownLevels(columnsWithFields, valueCells);

        this._updateVisibleLevels();
    }

    _prepareDrilldownLevels(columnsAndFields: ColumnAndFieldInfo[], valueCells: FdpCell[]) {
        this._headerColumnDef = [];
        this._headerRow = [];

        _.each(this.tablesConfig, (table, levelIdx: number) => {
            const fields = table.dimensions.map(d => d.fieldId);
            const levelType = table.type;
            const isLastLevel = levelIdx === this.tablesConfig.length - 1;
            const levelHeaders: IPivotTableLevelHeader[] = [];

            const levelPivot = this._pivots[levelIdx];
            if (levelPivot == null) throw Error(`Pivot for level ${levelIdx} is not supplied`);

            const levelRow: FdpLevelWithType = {
                type: levelType,
                ignoreOwnFilters: table.ignoreOwnFilters,
                level: levelIdx,
                pivot: levelPivot,
                levelHeaders,
                columnDefinition: [],
                subLevels: [],
                maxVisibleSubLevel: 0
            };

            this._levels.push(levelRow);

            const levelFields = _.map(fields, x => this._getFieldByName(x));

            if (levelType === "pivot") {
                if (levelIdx === 0) {
                    this._initPivotColumnsConfig(levelHeaders);
                    this._addFilterIconColumn(this.filters);
                    this._addToColumnsDef(columnsAndFields);
                }

                this._getPivotSubLevels(
                    levelIdx,
                    levelFields,
                    fields,
                    isLastLevel,
                    levelHeaders,
                    valueCells,
                    levelRow
                );
            }

            if (levelType === "table") {
                this._getTableSubLevels(levelIdx, fields, levelHeaders, valueCells, levelRow);

                if (levelIdx === 0) {
                    this._initTableColumnsConfig();
                    this._addFilterIconColumn(this.filters);
                    this._addToColumnsDef(columnsAndFields);
                }
            }
        });
    }

    _initPivotColumnsConfig(levelHeaders: IPivotTableLevelHeader[]) {
        this._headerColumnDef = [
            {
                id: levelFieldColumnId,
                columnCls: "crop",
                width: "*"
            }
        ];

        this._headerRow = [
            {
                id: levelFieldColumnId,
                type: "levels",
                levelHeaders
            }
        ];

        this._totalsColumnDef = [
            {
                id: levelFieldColumnId,
                columnCls: "crop",
                width: "*"
            }
        ];

        this._totalsRow = [
            {
                id: levelFieldColumnId,
                type: "text",
                value: this._lgTranslate.translate("_Flexible._.Total")
            }
        ];
    }

    _getPivotSubLevels(
        levelIdx: number,
        levelFields: FieldInfo[],
        fields: string[],
        isLastLevel: boolean,
        levelHeaders: IPivotTableLevelHeader[],
        valueCells: FdpCell[],
        levelRow: FdpLevelWithType
    ) {
        const levelProperties = this._getLevelPropertiesById(levelIdx);

        _.each(levelFields, (levelField, fieldIdx: number) => {
            const subLevelId = `row${fieldIdx + 1}`;
            const isLastSubLevel = fieldIdx === fields.length - 1;
            const isVeryLast = isLastLevel && isLastSubLevel;

            const convertedHeaderColumnDef = this._headerColumnDef.filter(h => !h.usage);

            if (convertedHeaderColumnDef.length !== this._headerColumnDef.length) {
                convertedHeaderColumnDef.splice(0, 0, {
                    id: levelFieldColumnId,
                    columnCls: "crop",
                    width: "*"
                });
            }

            const levelColumnsDefinition = [...convertedHeaderColumnDef];

            const subLevelRow: FdpSubLevel = {
                levelId: subLevelId,
                openOnClick: !isLastSubLevel,
                isVeryLast,
                cells: []
            };

            if (!isLastSubLevel) {
                levelColumnsDefinition.unshift({ id: "expand", type: "expand" });
                subLevelRow.cells.unshift({ id: "expand", type: "expand" });
            }

            for (let j = fieldIdx; j >= 1; j--) {
                const cellId = `empty${j}`;
                levelColumnsDefinition.unshift({ id: cellId, type: "empty" });
                subLevelRow.cells.unshift({ id: cellId, type: "empty" });
            }

            levelRow.subLevels.push(subLevelRow);

            // Add the level to the columns definition
            levelRow.columnDefinition.push({
                id: subLevelId,
                columns: levelColumnsDefinition
            });
            // Add this level to the first column header
            levelHeaders.push({
                header:
                    levelProperties?.[levelField.field]?.title ??
                    levelField.name ??
                    (levelField.nameLc ? undefined : ""),
                headerLc: levelField.nameLc ?? undefined,
                orderBy: levelField.field
            });

            // Add cells to the model of the current row
            subLevelRow.cells.push({
                ...this._getDimensionCell(levelField, levelProperties),
                id: levelFieldColumnId
            });

            if (this.filters !== undefined) {
                subLevelRow.cells.push({
                    id: filterColumnId,
                    type: "filter",
                    fieldName: levelField.field
                });
            }

            subLevelRow.cells.push(...valueCells);
        });
    }

    _initTableColumnsConfig() {
        this._totalsColumnDef = [
            {
                id: "totals",
                columnCls: "crop",
                width: "*"
            }
        ];

        this._totalsRow = [
            {
                id: "totals",
                type: "text",
                value: this._lgTranslate.translate("_Flexible._.Total")
            }
        ];
    }

    _getTableSubLevels(
        levelIdx: number,
        fields: string[],
        levelHeaders: IPivotTableLevelHeader[],
        valueCells: FdpCell[],
        levelRow: FdpLevelWithType
    ) {
        const subLevelId = `row${levelIdx}`;
        const subLevelRow: FdpSubLevel = {
            levelId: subLevelId,
            openOnClick: false,
            isVeryLast: levelIdx === this.tablesConfig.length - 1,
            cells: []
        };
        const levelColumnsDefinition: FdpSpecifiedColumnDefinition[] = [];

        const levelFields = _.map(fields, x => this._getFieldByName(x));

        let filtersCount = 0;

        _.each(levelFields, levelField => {
            const iconAdded = this._addLevelField(
                subLevelRow,
                levelHeaders,
                levelColumnsDefinition,
                levelField,
                levelIdx,
                filtersCount
            );
            if (iconAdded) filtersCount++;
        });

        this._addValueField(subLevelRow, levelHeaders, levelColumnsDefinition, valueCells);

        // Add the level to the columns definition
        levelRow.columnDefinition.push({
            id: subLevelId,
            columns: levelColumnsDefinition
        });
        levelRow.subLevels.push(subLevelRow);
    }

    _drillDown(levelIdx: number, subLevelIdx: number, row: { [K: string]: unknown }): void {
        if (row.$disabled) return;

        const levelDef = this._levels[levelIdx];
        const subLevelDef = levelDef.subLevels[subLevelIdx];

        // Drilling up
        if (this._selectedKeys[levelIdx] !== undefined) {
            this._selectedKeys = this._selectedKeys.splice(0, levelIdx);
        } else {
            if (subLevelDef.openOnClick || subLevelDef.isVeryLast) return;

            this._selectedKeys[levelIdx] = [];
            const levels = this.tablesConfig.map(t => t.dimensions.map(d => d.fieldId));
            for (const key of levels[levelIdx]) {
                this._selectedKeys[levelIdx].push({
                    level: subLevelIdx, // is this value actually correct / useful? I would expect it to match the index of the loop above
                    fieldName: key,
                    value: row[key]
                });
            }
        }

        this._updateVisibleLevels();
        this.drillChange.next(this._selectedKeys);
    }

    _drilldownGoUp(): void {
        if (this._selectedKeys.length <= 0) return;
        this._drillDown(this._selectedKeys.length - 1, 0, {});
    }

    // TODO: Figure out if we need both this method and _onOrderByChange
    _onLevelsOrderByChangePivot(event: {
        level: FdpLevelWithType;
        orderBy: IOrderByPerLevelSpecification;
    }): void {
        const { level, orderBy } = event;
        level.pivot.orderBy = orderBy;
        level.pivot.refilter();
    }

    protected override _sortDrilldownLevel(
        dimensionFields: string[],
        levelIndex: number,
        orderByField: string
    ) {
        super._sortDrilldownLevel(dimensionFields, levelIndex, orderByField);

        // If sorted level ignores filters, we need to sort "all" array as well
        const level = this._levels[levelIndex];
        if (level.ignoreOwnFilters) {
            level.pivot.sort(false, true);
        }
    }

    override _onOrderByChange(colId: string): void {
        // visible level is always defined, if there's anything to trigger the sort event
        const maxVisibleLevel = this._maxVisibleLevel!;
        const maxVisibleSubLevelIndex = maxVisibleLevel.maxVisibleSubLevel;

        const columnOrderId =
            maxVisibleLevel.pivot.orderBy[maxVisibleSubLevelIndex] === colId ? "-" + colId : colId;

        this._currentColumnSorting.set(columnOrderId);
        this.levels.forEach((pivotLevel, pivotIndex) => {
            this._sortDrilldownLevel(pivotLevel, pivotIndex, columnOrderId);
        });
    }

    _onLevelsOrderByChangeSublevel(
        level: FdpLevelWithType,
        orderBy: IOrderByPerLevelSpecification
    ): void {
        level.pivot.orderBy = orderBy;
        level.pivot.refilter();
    }

    _onMaxVisibleSubLevelChange(level: number, value: number): void {
        this._levels[level].maxVisibleSubLevel = value;
    }

    override _addToColumnsDef(columnsAndFields: ColumnAndFieldInfo[]): void {
        const addColumnToGroup = x => {
            switch (x.type) {
                case "default":
                    this._addDefaultColumn(x, this._getField(x), x.index);
                    break;

                case "difference":
                    this._addDifferenceColumn(x, this._getField(x), x.index);
                    break;

                case "formula":
                    this._addFormulaColumn(x, x.index);
                    break;

                case "widget":
                    this._addWidgetColumn(x, x.index);
                    break;

                default:
                    throw Error();
            }
        };

        // @TODO: this index used for lgCol specify for widget column type
        let columnIndex = -1;
        this.columnsGroups.forEach((group, index) => {
            if ("columns" in group) {
                const headerRowGroup: HeaderRowGroup = {
                    id: `${group.title}${index}`,
                    title: group.title,
                    type: group.columnType,
                    colspan: group.columns.length,
                    referenceIdx: group.referenceIdx ?? 0,
                    children: []
                };

                if (group.columns && group.columns.length > 0) {
                    group.columns.forEach(column => {
                        addColumnToGroup({ ...column, index: ++columnIndex });
                        const addedColumn = this._headerRow.pop();

                        headerRowGroup.children.push(addedColumn as HeaderRowColumn);
                    });

                    headerRowGroup.colspan = headerRowGroup.children.length;

                    this._headerRow.push(headerRowGroup as FdpColumnHeader);
                }
                return;
            }

            addColumnToGroup({ ...group, index: ++columnIndex });
        });
    }

    protected _pageReferenceSelect(reference: { slotIdx: number; value: string }) {
        const { slotIdx, value } = reference;
        this._onReferenceSelected(slotIdx, value);
    }

    protected _updateVisibleLevels(): void {
        this._maxVisibleLevelIdx = this._selectedKeys.length;
        this._visibleLevels = _.take(this._levels, this._maxVisibleLevelIdx + 1);
        this._maxVisibleLevel = _.last(this._visibleLevels);
        this._updateVisibility();
    }

    private _addFilterIconColumn(filters: LgFilterSet | undefined): void {
        if (filters !== undefined) {
            this._headerColumnDef.push({
                id: filterColumnId,
                type: "icons",
                width: ICON_COLUMN_WIDTH
            });

            this._headerRow.push({
                type: "icons",
                id: filterColumnId
            });

            this._totalsColumnDef.push({
                id: filterColumnId,
                type: "icons",
                width: ICON_COLUMN_WIDTH
            });

            this._totalsRow.push({
                id: filterColumnId,
                type: "filter",
                fieldName: undefined
            });
        }
    }

    private _addLevelField(
        subLevelRow: FdpSubLevel,
        levelHeaders: any,
        levelColumnsDefinition: FdpSpecifiedColumnDefinition[],
        levelField: FieldInfo,
        levelIdx: number,
        filtersCount: number
    ): boolean {
        const isHeaderRow = levelIdx === 0;
        const levelProperties = this._getLevelPropertiesById(levelIdx);
        const dimensionCell = this._getDimensionCell(levelField, levelProperties);
        subLevelRow.cells.push({
            ...dimensionCell
        });

        const width = levelProperties?.[levelField.field]?.width;

        const cellBase = {
            width: width ? width : "*",
            id: dimensionCell.id
        };

        const levelFieldTitle: IPivotTableLevelHeaderFlatTable = {
            ...cellBase,
            header:
                levelProperties?.[levelField.field]?.title ??
                levelField.name ??
                (levelField.nameLc ? undefined : ""),
            headerLc: levelField.nameLc ?? undefined,
            headerType: "dimension",
            orderBy: levelField.field
        };
        levelHeaders.push({ ...levelFieldTitle });

        if (isHeaderRow) {
            this._headerRow.push({
                id: levelFieldTitle.id,
                type: "default",
                orderBy: levelFieldTitle.orderBy as string,
                column: { type: "default", field: levelFieldTitle.id },
                header: levelFieldTitle.header,
                headerLc: levelFieldTitle.headerLc
            });
            this._headerColumnDef.push({
                id: levelFieldTitle.id,
                columnCls: levelFieldTitle.columnCls,
                width: levelFieldTitle.width,
                usage: "table"
            });
        }

        levelColumnsDefinition.push({
            ...cellBase
        });

        return this._addFilterIcon(
            this.filters,
            subLevelRow,
            levelHeaders,
            levelColumnsDefinition,
            filterColumnId + "_" + levelIdx + "_" + filtersCount,
            levelField,
            isHeaderRow
        );
    }

    private _addFilterIcon(
        filters: LgFilterSet | undefined,
        subLevelRow: FdpSubLevel,
        levelHeaders: IPivotTableLevelHeaderFlatTable[],
        levelColumnsDefinition: FdpSpecifiedColumnDefinition[],
        id: string,
        levelField: FieldInfo,
        isHeaderRow: boolean
    ): boolean {
        if (filters !== undefined) {
            const cellBaseFilter = {
                width: ICON_COLUMN_WIDTH,
                id,
                type: "icons"
            };
            subLevelRow.cells.push({
                ...cellBaseFilter,
                type: "filter",
                fieldName: levelField.field
            });
            levelHeaders.push({
                ...cellBaseFilter,
                headerType: "icons"
            });
            levelColumnsDefinition.push({
                ...cellBaseFilter
            });

            if (isHeaderRow) {
                this._headerColumnDef.push({
                    ...cellBaseFilter,
                    usage: "table"
                });

                this._headerRow.push({
                    type: "icons",
                    id: cellBaseFilter.id
                });
            }
            return true;
        } else return false;
    }

    private _addValueField(
        subLevelRow: FdpSubLevel,
        levelHeaders: any[],
        levelColumnsDefinition: FdpSpecifiedColumnDefinition[],
        valueCells: FdpCell[]
    ): void {
        subLevelRow.cells.push(...valueCells);

        valueCells.forEach(value => {
            if (value.header) {
                const cellBase = {
                    width: value.header.width,
                    id: value.id,
                    columnCls: value.header.columnCls
                };
                levelHeaders.push({
                    ...cellBase,
                    header: value.header.header,
                    headerLc: value.header.headerLc,
                    headerType: value.header.type,
                    column: value.header.column,
                    formula: value.header.formula,
                    orderBy: value.id
                });

                levelColumnsDefinition.push({
                    type: value.type === "widget" ? "icons" : "standard",
                    ...cellBase
                });
            }
        });
    }

    private _getLevelPropertiesById(levelIdx: number): Dictionary<PivotTableLevel> {
        const levelProperties = {};
        this.tablesConfig[levelIdx].dimensions.forEach(d => {
            const { displayMode, width } = d;
            levelProperties[d.fieldId] = {
                title: d.name,
                displayMode,
                width
            };
        });

        return levelProperties;
    }
}
