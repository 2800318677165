import { FlexibleDrilldownPivotTableState, PageWidgetConfigBase, WidgetState } from "../../types";
import { GroupOrColumn, Column, TableConfig, PivotTableType } from "../../types/configuration";
export const PIVOT_TABLE_WIDGET_2 = "PivotTableWidget2";

export type PivotTableGroupOrColumn = GroupOrColumn;
export type PivotTableWidgetColumn = Column;
export type PivotTableLevelType = PivotTableType;

export interface PivotTableWidgetConfig extends PageWidgetConfigBase {
    title: string;
    columns: PivotTableGroupOrColumn[];
    tablesConfig: TableConfig[];
}

export type PivotTableWidgetState = WidgetState & {
    version: number;
    tableState: FlexibleDrilldownPivotTableState | null;
};
