import { Component, input, InputSignal, output } from "@angular/core";
import { FdpColumnHeader, FdpDifferenceColumnChangeEvent, FdpLevel } from "../../../types";
import { PageReferencesService } from "../../../services/page-references/page-references.service";
import { IDropdownDefinition, LgColDefinitionComponent } from "@logex/framework/ui-core";
import { LgPivotTableBodyComponent } from "@logex/framework/lg-pivot-table";

@Component({
    selector: "lgflex-pivot-header-main-level",
    templateUrl: "./pivot-header-main-level.html"
})
export class PivotHeaderMainLevelComponent {
    level = input<FdpLevel>();
    pivotBody = input<LgPivotTableBodyComponent>();
    colDefinition = input<LgColDefinitionComponent>();
    referencesDropdown = input<IDropdownDefinition<string> | undefined>();
    pageReferences = input<PageReferencesService>();
    columnSortBy = input<string>();
    headerRow: InputSignal<FdpColumnHeader[]> = input([]);
    pivotWrapperWidth = input(0);
    // --------------------------------------------------
    onOrderByChange = output<string>();
    onReferenceChange = output<{ slotIdx: number; value: string }>();
    onDifferenceColumnChange = output<FdpDifferenceColumnChangeEvent>();
    onLevelsOrderByChange = output<any>();
}
