<div
    [lgColDefinitionWidth]="pivotWrapperWidth() - 10"
    [lgColDefinition]="colDefinition()"
    class="table__row table__row--header table__row--flat-header"
    lgColRow="headerRow"
>
    @for (headerCell of $any(headerRow()); track headerCell.id) {
        @if (headerCell.type === "referenceSlot" || headerCell.type === "simple") {
            <div class="table__column table__column-group">
                @if (headerCell.type === "referenceSlot") {
                    <lgflex-reference-slot
                        [headerCell]="headerCell"
                        [pageReferences]="pageReferences()"
                        [referencesDropdown]="referencesDropdown()"
                        [columnSortBy]="columnSortBy()"
                        (onReferenceChange)="onReferenceChange.emit($event)"
                        (onOrderByChange)="onOrderByChange.emit($event)"
                        (onDifferenceColumnChange)="onDifferenceColumnChange.emit($event)"
                    />
                }
                @if (headerCell.type === "simple") {
                    <lgflex-simple-group
                        [headerCell]="headerCell"
                        [pageReferences]="pageReferences()"
                        [columnSortBy]="columnSortBy()"
                        (onOrderByChange)="onOrderByChange.emit($event)"
                        (onDifferenceColumnChange)="onDifferenceColumnChange.emit($event)"
                    />
                }
            </div>
        }
        @if (headerCell.type !== "simple" && headerCell.type !== "referenceSlot") {
            <lgflex-flexible-drilldown-table-header-row
                [lgCol]="headerCell.id"
                [headerCell]="headerCell"
                [sortBy]="columnSortBy()"
                [pageReferences]="pageReferences()"
                (orderByChange)="onOrderByChange.emit($event)"
                (differenceColumnChange)="onDifferenceColumnChange.emit($event)"
            />
        }
    }
</div>
