import { StaticProvider, TemplateRef, Type } from "@angular/core";
import { IPivotTableLevelHeader } from "@logex/framework/lg-pivot-table";
import {
    FieldInfo,
    PivotTableColumn,
    PivotTableColumnDefault,
    PivotTableColumnDifference,
    PivotTableColumnFormula,
    PivotTableColumnFormulaFormatting,
    PivotTableColumnFormulaType,
    PivotTableColumnWidget,
    WidgetComponent
} from ".";
import { DifferenceColumnConfiguration } from "../components/flexible-pivot-difference-column/difference-column-header.types";
import { IOrderByPerLevelSpecification, LgPivotInstance } from "@logex/framework/lg-pivot";
import { PivotExpandedState } from "../utilities";
import { DefinitionDisplayMode } from "@logex/framework/lg-application";
import { FlexibleLayoutWidgetVisualizationContext } from "../components/flexible-layout/flexible-layout.types";

export interface FdpLevel {
    level: number;
    pivot: LgPivotInstance;
    levelHeaders: IPivotTableLevelHeader[];

    columnDefinition: FdpRowDefinition[];
    subLevels: FdpSubLevel[];

    maxVisibleSubLevel: number;
}

export interface FdpSubLevel {
    levelId: string;
    openOnClick: boolean;
    isVeryLast: boolean;
    cells: FdpCell[];
}

export interface FdpColumnContext {
    items: Record<string, unknown>;
    row: { [K: string]: number } | null;
    rowIndex: number;
}

export interface FdpRowDefinition {
    id: string;
    columns: FdpColumnDefinition[];
}

export interface FdpColumnDefinition {
    id: string;
    type?: string;
    headerCls?: string;
    columnCls?: string;
    width?: string | number;
}

export type FdpColumnHeader =
    | FdpColumnHeaderDefault
    | FdpColumnSimpleHeaderGroup
    | FdpColumnHeaderDifference
    | FdpColumnHeaderIcons
    | FdpColumnHeaderLevels
    | FdpColumnHeaderGroup
    | FdpColumnHeaderWidget
    | FdpColumnHeaderFormula;

export interface FdpColumnHeaderDefault {
    type: "default";
    id: string;
    column: PivotTableColumnDefault;
    header?: string;
    headerLc?: string;
    orderBy: string;
    fieldNumber?: string;
}

export interface FdpColumnHeaderDifference {
    type: "difference";
    id: string;
    column: PivotTableColumnDifference;
    leftColumnHeader?: FdpColumnHeaderDefault;
    rightColumnHeader?: FdpColumnHeaderDefault;
    header?: string;
    formula?: string;
    comparisonType: "absolute" | "relative";
    orderBy: string;
}

export interface FdpColumnHeaderFormula {
    type: "formula";
    id: string;
    column: PivotTableColumnFormula;
    header: string;
    formula: PivotTableColumnFormulaType;
    orderBy: string;
}

export interface FdpColumnHeaderIcons {
    type: "icons";
    id: string;
}

export interface FdpColumnHeaderLevels {
    type: "levels";
    id: string;
    levelHeaders: IPivotTableLevelHeader[];
}

export interface FdpColumnHeaderGroup {
    type: "group";
    id: string;
    colspan: number;
    referenceIdx: number;
    children: Array<FdpColumnHeaderDefault | FdpColumnHeaderLevels>;
}

export interface FdpColumnSimpleHeaderGroup {
    type: "simple" | "referenceSlot";
    id: string;
    colspan: number;
    referenceIdx: number;
    title?: string;
    children: Array<FdpColumnHeaderDefault | FdpColumnHeaderWidget>;
}

export interface FdpColumnHeaderWidget {
    type: "widget";
    id: string;
    column: PivotTableColumnWidget;
    header?: string;
}

export type FdpCellType =
    | "empty"
    | "expand"
    | "default"
    | "dimension"
    | "filter"
    | "text"
    | "templateRef"
    | "widget";

export type FdpHeaderType = "dimension" | "default" | "difference" | "formula" | "widget" | "icons";

export type FdpCellHeader = {
    type: FdpHeaderType;
    header?: string;
    headerLc?: string;
    column?: PivotTableColumnDifference;
    formula?: PivotTableColumnFormulaType;
    columnCls?: string;
    width?: string | number;
};

export interface FdpLevelFlatTable {
    level: number;
    pivot: LgPivotInstance;
    levelHeaders: IPivotTableLevelHeaderFlatTable[];

    columnDefinition: FdpRowDefinition[];
    subLevels: FdpSubLevel[];

    maxVisibleSubLevel: number;
}

export interface IPivotTableLevelHeaderFlatTable extends IPivotTableLevelHeader {
    width?: string | number;
    headerType?: FdpHeaderType;
    type?: string;
    columnCls?: string;
    id: string;
    column?: PivotTableColumnDifference;
    formula?: PivotTableColumnFormulaType;
}

export interface FdpCellVisualizationWidget {
    widgetType: Type<WidgetComponent<FlexibleLayoutWidgetVisualizationContext>>;
    providers: StaticProvider[] | undefined;
    config: object;
}

export type FdpCell = {
    id: string;
    type: FdpCellType;
    header?: FdpCellHeader;
    visualizationWidget?: FdpCellVisualizationWidget;
    conditionalFormatting?: boolean;
} & (
    | {
          type: "empty" | "expand";
      }
    | {
          type: "default";
          fieldName: string;
          formatFn: (value: number) => string;
          formatType: PivotTableColumnFormulaFormatting;
          tooltip?: {
              fieldName: string;
              formatFn: (value: number) => string;
          };
      }
    | {
          type: "dimension";
          fieldName: string;
          formatFn: (value: number) => string;
      }
    | {
          type: "filter";
          fieldName: string | undefined;
      }
    | {
          type: "text";
          value: string;
      }
    | {
          type: "templateRef";
          templateRef: TemplateRef<FdpColumnContext>;
      }
    | {
          type: "widget";
          widgetType: Type<WidgetComponent> | undefined;
          providers: StaticProvider[] | undefined;
          config: object | null;
      }
);

// ----------------------------------------------------------------------------------
export interface FdpDifferenceColumnChangeEvent {
    column: PivotTableColumnDifference;
    changes: Partial<DifferenceColumnConfiguration>;
}

// ----------------------------------------------------------------------------------
export type DrilldownKeyItem = {
    level: number;
    fieldName: string;
    value: any;
};

export type PivotCellWidgetContext = {
    items: any[];
    scheme: FieldInfo[];
    rowIndex: number;
    row: any;
    selectedKeys: DrilldownKeyItem[][];
    levels: string[];
};
// ----------------------------------------------------------------------------------
//
export interface DifferenceColumnChangeEvent {
    column: PivotTableColumnDifference;
    changes: Partial<DifferenceColumnConfiguration>;
}

// ----------------------------------------------------------------------------------
export interface FlexibleDrilldownPivotTableState {
    version: number;
    levels: string[][];
    selectedKeys: DrilldownKeyItem[][];
    orderBy: IOrderByPerLevelSpecification[];
    expanded: Array<PivotExpandedState | null>;
}

// ----------------------------------------------------------------------------------
export const levelFieldColumnId = "__name";
export const filterColumnId = "__filter";

export const ICON_COLUMN_WIDTH = 28;

export function customColumnId(index: number | string): string {
    return `__custColumn${index}`;
}

export type ColumnAndFieldInfo = {
    column: PivotTableColumn;
    field: FieldInfo | undefined;
    index: number;
};

export interface PivotTableLevel {
    title: string;
    displayMode?: DefinitionDisplayMode;
    width?: number;
}

export const COLUMN_WIDTH_DEFAULT = 100;
