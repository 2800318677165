import { inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, OperatorFunction, pipe, Subject } from "rxjs";
import { INavNode, LG_APP_SESSION } from "@logex/framework/lg-application";
import { FlexibleLayoutStorageGatewayService, LayoutNavigation } from "../flexible-layout-storage";
import { map, switchMap } from "rxjs/operators";
import { generateId } from "../../utilities";
import { LgConsole } from "@logex/framework/core";

export const FLEXIBLE_LAYOUTS_NAVIGATION = new InjectionToken<IFlexibleLayoutsNavigation>(
    "flexibleLayoutsNavigation"
);

export const FLEXIBLE_PAGE_DATA_KEY = "flexiblePage";

export interface IFlexibleLayoutsNavigation {
    load(): Observable<LayoutNavigation[]>;

    addFlexibleLayouts(navTree: INavNode[]): OperatorFunction<any, INavNode[]>;

    signalLayoutsNavigationChanged(): void;

    readonly reloadLayouts$: Observable<void>;
}

@Injectable()
export class FlexibleLayoutsNavigationService implements IFlexibleLayoutsNavigation {
    private readonly _session = inject(LG_APP_SESSION);
    private readonly _layoutStorageGatewayService = inject(FlexibleLayoutStorageGatewayService);
    private readonly _lgConsole: LgConsole = inject(LgConsole);

    private _reloadSharedLayoutsSubject = new Subject<void>();
    readonly reloadLayouts$ = this._reloadSharedLayoutsSubject.asObservable();

    load(): Observable<LayoutNavigation[]> {
        return this._layoutStorageGatewayService.loadNavigationLayouts(this._session.clientId);
    }

    signalLayoutsNavigationChanged(): void {
        this._reloadSharedLayoutsSubject.next();
    }

    addFlexibleLayouts(navTree: INavNode[]): OperatorFunction<any, INavNode[]> {
        return pipe(
            switchMap(() => {
                return this.load().pipe(
                    map(response => {
                        return navTree.map(node => {
                            this._traverseNavTree(node, item => {
                                if (item.data && item.data[FLEXIBLE_PAGE_DATA_KEY] !== undefined) {
                                    if (item.matchChildrenByQueryParams !== undefined) {
                                        const flexiblePage = item.data[FLEXIBLE_PAGE_DATA_KEY];
                                        const pageLayouts = response.filter(
                                            layout => layout.page === flexiblePage
                                        );
                                        item.children = this._mapToNavNode(pageLayouts);
                                    } else {
                                        this._lgConsole.error(
                                            "matchChildrenByQueryParams is not defined for node",
                                            item
                                        );
                                    }
                                }
                            });

                            return node;
                        });
                    })
                );
            })
        );
    }

    private _mapToNavNode(layouts: LayoutNavigation[]): INavNode[] {
        return layouts.map(layout => {
            if (layout.children) {
                return {
                    path: "",
                    id: `group-${generateId()}`,
                    lid: layout.name,
                    children: layout.children.map(l => {
                        return {
                            path: "",
                            id: `layout-${l.layoutId}`,
                            lid: l.name,
                            queryParams: [{ name: "layoutId", value: `${l.layoutId}` }]
                        };
                    })
                };
            } else {
                return {
                    path: "",
                    id: `layout-${layout.layoutId}`,
                    lid: layout.name,
                    queryParams: [{ name: "layoutId", value: `${layout.layoutId}` }]
                };
            }
        });
    }

    private _traverseNavTree(node: INavNode, callback: (node: INavNode) => void) {
        callback(node);
        if (node.children) {
            for (const child of node.children) {
                this._traverseNavTree(child, callback);
            }
        }
    }
}
