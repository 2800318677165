import { Provider } from "@angular/core";
import {
    LG_BOOKMARK_STORE,
    LgBookmarksStateService,
    LgFilterSetStateStore
} from "@logex/framework/lg-layout";

import { FlexDataClientService } from "./services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "./services/page-references/page-references.service";
import { WidgetTypesRegistry } from "./services/widget-types-registry/widget-types-registry.service";
import { FlexibleLayoutStorageService } from "./services/flexible-layout-storage/flexible-layout-storage.service";
import { FlexibleFilterFactory } from "./services/flexible-filter-factory/flexible-filter-factory";
import { FlexibleLayoutUpgraderService } from "./services/flexible-layout-upgrader";
import { LayoutBookmarksStore } from "./services/layout-bookmarks-store";
import { LG_FLEX_DATA_SOURCES_SERVICE } from "./services/flex-data-sources/flex-data-sources.types";
import { FlexibleAnalyticsService } from "./services/flexible-analytics/flexible-analytics.service";

type useFlexibleArguments = {
    dataSourceServiceClass?: any;
};

export function useFlexible(args?: useFlexibleArguments): Provider[] {
    const res: Provider[] = [
        FlexibleAnalyticsService,
        FlexDataClientService,
        PageReferencesService,
        WidgetTypesRegistry,
        FlexibleLayoutStorageService,
        FlexibleFilterFactory,
        FlexibleLayoutUpgraderService,
        LgFilterSetStateStore,
        LayoutBookmarksStore,
        {
            provide: LG_BOOKMARK_STORE,
            useExisting: LgFilterSetStateStore,
            multi: true
        },
        {
            provide: LG_BOOKMARK_STORE,
            useExisting: LayoutBookmarksStore,
            multi: true
        },
        LgBookmarksStateService
    ];

    if (args) {
        if (args.dataSourceServiceClass) {
            res.push({
                provide: LG_FLEX_DATA_SOURCES_SERVICE,
                useClass: args.dataSourceServiceClass
            });
        }
    }

    return res;
}
