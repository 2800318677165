import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    inject,
    Injector,
    INJECTOR,
    Input,
    Output,
    ViewChild,
    ViewContainerRef,
    OnInit,
    viewChild,
    ElementRef,
    AfterViewInit
} from "@angular/core";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import {
    EmbeddedWidgetContext,
    FieldInfo,
    PivotTableColumnDifferenceMode,
    PivotTableColumnFormulaFormatting,
    WidgetUsage
} from "../../../../../../types";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    CONDITIONAL_FORMATTING_ID,
    DEFAULT_CONDITIONAL_FORMATTING_CONFIG
} from "../../../../../../components/conditional-formatting/conditional-formattings.types";
import { WidgetTypesRegistry } from "../../../../../../services/widget-types-registry";
import { getEmbeddedWidgetConfiguration } from "../../../../../../utilities/configurator-helpers";
import { FlexibleLayoutUpgraderService } from "../../../../../../services/flexible-layout-upgrader";
import { PageReferencesService } from "../../../../../../services/page-references/page-references.service";
import { getFormatTypeFromValueField } from "../../../../../../utilities/getFormatTypeFromValueField";
import { CustomColumn } from "./pivot-table-columns-value-properties.types";

import { Dictionary } from "lodash";
import * as _ from "lodash-es";

@Component({
    selector: "lgflex-pivot-table-value-column-properties",
    templateUrl: "./pivot-table-columns-value-properties.component.html",
    providers: [...useTranslationNamespace("_Flexible.PivotTableConfiguration.ValueSelection")],
    styleUrls: ["./pivot-table-columns-value-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsValuePropertiesComponent implements OnInit, AfterViewInit {
    _lgTranslate = inject(LgTranslateService);
    _widgetTypes = inject(WidgetTypesRegistry);
    _upgrader = inject(FlexibleLayoutUpgraderService);
    _pageReferences = inject(PageReferencesService);
    private _injector = inject<Injector>(INJECTOR);

    @Input("currentValue") set currentValue(value: CustomColumn) {
        this._currentValue = value;
        if (this._currentValue.type === "default") {
            this._currentValue.referencedField = `${this._currentValue.field}${this._currentValue.referenceIdx}`;
        }
        this._setVisualizationWidgetType(this._getCurrentVisualization());
    }

    get currentValue() {
        return this._currentValue;
    }

    @Input("areReferencesAllowed") areReferencesAllowed: boolean | undefined;
    @Input("referenceDropdown") referenceDropdown: IDropdownDefinition<number> | undefined;
    @Input("referencedFieldsDropdown") referencedFieldsDropdown:
        | IDropdownDefinition<any>
        | undefined;

    @Input("fieldsDropdownDefinition") fieldsDropdownDefinition: IDropdownDefinition<string>;

    @Input("pivotWidgetTypeDropdown") pivotWidgetTypeDropdown: IDropdownDefinition<string>;

    @Input("schemaLookup") schemaLookup: Dictionary<FieldInfo> = {};

    @Output() public readonly currentValueUpdate = new EventEmitter<any | null>();
    private _visualizationInlineComponentContainer = viewChild<string, ViewContainerRef>(
        "visualizationInlineComponentContainer",
        { read: ViewContainerRef }
    );

    protected _currentValue: CustomColumn = null;
    protected _visualizationWidgetTypeDropdown: IDropdownDefinition<string> | undefined;
    protected _differenceModeDropdown: IDropdownDefinition<PivotTableColumnDifferenceMode> =
        dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: [
                {
                    code: "diff",
                    name: this._lgTranslate.translate(".Difference")
                },
                {
                    code: "growth",
                    name: this._lgTranslate.translate(".Growth")
                }
            ]
        });

    ngOnInit() {
        const visualizationWidgets = this._widgetTypes.getAllForUsage(WidgetUsage.Visualization);

        this._visualizationWidgetTypeDropdown = dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: [
                {
                    id: null,
                    name: "-"
                },
                {
                    id: CONDITIONAL_FORMATTING_ID,
                    name: this._lgTranslate.translate(
                        "_Flexible.ConditionalFormattingConfiguration.Title"
                    )
                },
                ...visualizationWidgets.map(x => ({
                    id: x.id,
                    name: this._lgTranslate.translate(x.nameLc)
                }))
            ]
        });
    }

    ngAfterViewInit() {
        this._setVisualizationWidgetType(this._getCurrentVisualization());
    }

    protected _getCurrentVisualization(): string | null {
        if (this.currentValue?.visualizationWidget) {
            return this.currentValue.visualizationWidget.type;
        }
        if (this.currentValue?.conditionalFormatting) {
            return CONDITIONAL_FORMATTING_ID;
        }
        return null;
    }

    protected async _setVisualizationWidgetType(visualizationId: string | null): Promise<void> {
        if (
            this.currentValue === null ||
            this._visualizationInlineComponentContainer() === undefined
        )
            return;

        const context: EmbeddedWidgetContext = {
            formatType: this._getColumnFormat(this.currentValue)
        };

        this.currentValue.visualizationWidget = await getEmbeddedWidgetConfiguration(
            this._injector,
            this._widgetTypes,
            this._pageReferences,
            this._upgrader,
            this._visualizationInlineComponentContainer(),
            this.currentValue.visualizationWidget,
            visualizationId,
            context
        );

        if (visualizationId === CONDITIONAL_FORMATTING_ID) {
            this.currentValue.visualizationWidget = undefined;
            this.currentValue.conditionalFormatting =
                this.currentValue.conditionalFormatting ??
                structuredClone(DEFAULT_CONDITIONAL_FORMATTING_CONFIG);
        } else {
            this.currentValue.conditionalFormatting = undefined;
        }

        this.currentValueUpdate.emit(this.currentValue);
    }

    private _getColumnFormat(column: any): PivotTableColumnFormulaFormatting {
        switch (column.type) {
            case "default":
                return getFormatTypeFromValueField(this.schemaLookup[column.field]);
            case "difference":
                if (column.mode === "diff") {
                    return getFormatTypeFromValueField(this.schemaLookup[column.field]);
                } else {
                    return "percentage";
                }
            case "formula":
                return column.formatType;
            default:
                return "float";
        }
    }

    protected _onValuePropertyChange(value: string | number, property: any): void {
        this.currentValue[property as string] = value;

        this._updateCurrentValue();
    }

    protected _onReferencedFieldChange(referencedField: string): void {
        const lookupElementConfig = this.referencedFieldsDropdown.lookup[referencedField];
        if (this.currentValue.type === "default") {
            const { id, type, schemaName, title, width } = this.currentValue;

            this.currentValue = {
                ...lookupElementConfig,
                id,
                type,
                schemaName,
                title: this._lgTranslate.translate(lookupElementConfig.nameLc) ?? title,
                width: width ?? 100,
                referencedField
            };
        }
        this._updateCurrentValue();
    }

    protected _updateCurrentValue(value = null): void {
        this._setVisualizationWidgetType(this._getCurrentVisualization());
        if (this.currentValue.type === "formula") {
            this._updateFormulaField();
        }
        this.currentValueUpdate.emit(this.currentValue);
    }

    protected _onReferenceUpdate(reference: "left" | "right", selectedValue: number): void {
        if (this.currentValue === null) throw Error("Current column shouldn't be undefined.");

        if (
            this.currentValue.type === "difference" &&
            this.currentValue.referenceLeft === this.currentValue.referenceRight
        ) {
            const nextAvailableOption = _.times(this._pageReferences.slots.length)
                .filter(x => x !== selectedValue)
                .shift();

            if (nextAvailableOption !== undefined) {
                if (reference === "left") {
                    this.currentValue.referenceRight = nextAvailableOption;
                }
                if (reference === "right") {
                    this.currentValue.referenceLeft = nextAvailableOption;
                }
            }
        }

        this._updateCurrentValue();
    }

    protected _setPivotWidgetType(widgetTypeName: string): void {
        if (this.currentValue === null || this.currentValue.type !== "widget") return;

        const widgetType = this._widgetTypes.get(widgetTypeName);
        this.currentValue.widgetType = widgetTypeName;
        this.currentValue.title = this._lgTranslate.translate(widgetType.nameLc);

        this._updateCurrentValue();
    }

    private _updateFormulaField(): void {
        if (this.currentValue.type === "formula") {
            this.currentValue.field =
                this.currentValue.formula + "_" + JSON.stringify(this.currentValue.variables);
        }
    }
}
