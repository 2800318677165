<lgflex-dialog
    class="widget-configuration-dialog"
    [isReadOnly]="_isReadOnly"
    [isValid]="_isValid()"
    (saveAction)="_save()"
    (closeAction)="_close()"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ "_Flexible.WidgetConfiguration.WidgetInformation" | lgTranslate }}
                </lgflex-form-section-header>
                <lgflex-widget-configuration-section-information
                    [(widgetName)]="_widgetName"
                    [(widgetDescription)]="_widgetDescription"
                    [(widgetTooltip)]="_widgetTooltip"
                    [(widgetTooltipLink)]="_widgetTooltipLink"
                />
            </lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-form-section>
            <lgflex-form-section-column>
                <lgflex-form-row [label]="'.ChartType' | lgTranslate">
                    <lg-dropdown [definition]="_chartTypeDropdown" [(current)]="_chartType" />
                </lgflex-form-row>

                <lgflex-form-row [label]="'_Flexible.WidgetConfiguration.Levels' | lgTranslate">
                    <lgflex-pivot-levels-selector-legacy
                        [levels$]="_availableLevels$"
                        [(selectedLevelIds)]="_selectedLevelsIds"
                        (selectedLevelIdsChange)="_onValueDropdownChange()"
                        [minRowNum]="3"
                    />
                </lgflex-form-row>

                <lgflex-form-row *ngIf="_sizeDimension != null" [label]="'.Size' | lgTranslate">
                    <div class="size-rows">
                        <div class="size-rows__item">
                            <span>{{ ".Field" | lgTranslate }}</span>
                            <lg-dropdown
                                [definition]="_valueFieldDropdown!"
                                [(current)]="_sizeDimension.field"
                                (currentChange)="_onValueDropdownChange()"
                            />
                        </div>
                        <div class="size-rows__item" *ngIf="_areReferencesAllowed">
                            <span> {{ ".Reference" | lgTranslate }}</span>
                            <lg-dropdown
                                [definition]="_referenceDropdown"
                                [(current)]="_sizeDimension.reference"
                            />
                        </div>
                    </div>
                </lgflex-form-row>
            </lgflex-form-section-column>
        </lgflex-form-section>
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ ".XAxis" | lgTranslate }}
                </lgflex-form-section-header>
                <lgflex-configuration-dialog-formula
                    *ngIf="_xAxisColumnFormula"
                    [column]="_xAxisColumnFormula"
                    [fieldsDropdownDefinition]="_valueFieldDropdown"
                    [areReferencesAllowed]="_areReferencesAllowed"
                    [referencesDropdownDefinition]="_referenceDropdown"
                />
            </lgflex-form-section-column>
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ ".YAxis" | lgTranslate }}
                </lgflex-form-section-header>

                <lgflex-configuration-dialog-formula
                    *ngIf="_yAxisColumnFormula"
                    [column]="_yAxisColumnFormula"
                    [fieldsDropdownDefinition]="_valueFieldDropdown"
                    [areReferencesAllowed]="_areReferencesAllowed"
                    [referencesDropdownDefinition]="_referenceDropdown"
                />
            </lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
</lgflex-dialog>
