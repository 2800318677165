import { Inject, Injectable, Injector, INJECTOR, ViewContainerRef } from "@angular/core";
import {
    PivotTableWidgetConfig,
    PivotTableGroupOrColumn
} from "./pivot-table-widget.configuration.types";
import { PivotTableConfigurationDialog } from "./components/pivot-table-configuration-dialog/pivot-table-configuration-dialog.component";
import { validatePivotTableConfiguration } from "./components/pivot-table-configuration-dialog/pivot-table-configuration-validation";
import { DialogWidgetConfigurator } from "../../types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Injectable()
export class PivotTableWidgetConfigurator
    implements DialogWidgetConfigurator<PivotTableWidgetConfig>
{
    constructor(
        @Inject(INJECTOR) private _injector: Injector,
        public _lgTranslate: LgTranslateService,
        private _flexDataClient: FlexDataClientService,
        private _pageReferences: PageReferencesService,
        private _dialog: PivotTableConfigurationDialog,
        private _widgetTypes: WidgetTypesRegistry,
        viewContainerRef: ViewContainerRef
    ) {
        this._dialog = _dialog.bindViewContainerRef(viewContainerRef);
    }

    readonly type = "dialog";

    async show(config: PivotTableWidgetConfig): Promise<PivotTableWidgetConfig> {
        const response = await this._dialog.show({
            scheme: this._flexDataClient.scheme,
            pageReferences: this._pageReferences,
            widgetName: config.title ?? "",
            widgetDescription: config.description ?? "",
            widgetTooltip: config.tooltip ?? "",
            widgetTooltipLink: config.tooltipLink ?? "",
            columns: config.columns as PivotTableGroupOrColumn[],
            tablesConfig: config.tablesConfig
        });

        return {
            title: response.widgetName,
            description: response.widgetDescription,
            tooltip: response.widgetTooltip,
            tooltipLink: response.widgetTooltipLink,
            columns: response.columns,
            tablesConfig: response.tablesConfig
        };
    }

    validate(config: PivotTableWidgetConfig): boolean {
        return validatePivotTableConfiguration(
            this._lgTranslate,
            this._injector,
            config,
            this._pageReferences,
            this._flexDataClient.scheme,
            this._widgetTypes
        );
    }
}
