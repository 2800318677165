import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    LayoutCatalogGroupItemResponse,
    LayoutCatalogGroupRequestParams,
    LayoutCatalogGroupResponse,
    LayoutCatalogItemResponse,
    LayoutGroupItem
} from "../layout-management.types";
import { map } from "rxjs/operators";
import { LG_APP_SESSION } from "@logex/framework/lg-application";
import { FlexibleLayoutStorageGatewayService } from "../../../services/flexible-layout-storage/flexible-layout-storage-gateway.service";

@Injectable()
export class LayoutManagementGatewayAdapterService {
    private readonly _flexibleLayoutStorageGatewayService = inject(
        FlexibleLayoutStorageGatewayService
    );

    private readonly _session = inject(LG_APP_SESSION);

    loadSharedLayouts(page: string): Observable<LayoutGroupItem[]> {
        return this._flexibleLayoutStorageGatewayService
            .loadSharedLayouts(this._session.clientId, page, this._session.scenarioId)
            .pipe(
                map((response: LayoutCatalogGroupResponse) => {
                    return this._mapToLayoutGroupItem(response);
                })
            );
    }

    saveSharedLayouts(
        page: string,
        layoutGroupItems: LayoutGroupItem[],
        layoutCatalogGroupsToDelete: number[]
    ): Observable<string[]> {
        const args: LayoutCatalogGroupRequestParams = {
            page,
            layoutCatalogGroupsToDelete,
            clientId: this._session.clientId,
            layoutCatalogItems: layoutGroupItems
                .filter(layoutGroupItem => !this._isGroupItem(layoutGroupItem))
                .map((layoutGroupItem: LayoutGroupItem) => {
                    return {
                        id: layoutGroupItem.id,
                        sortOrder: layoutGroupItem.sortOrder,
                        showInNavigation: layoutGroupItem.showInNavigation,
                        groupId: layoutGroupItem.groupId
                    };
                }),
            layoutCatalogGroups: layoutGroupItems
                .filter(layoutGroupItem => this._isGroupItem(layoutGroupItem))
                .map((layoutGroupItem: LayoutGroupItem) => {
                    return {
                        id: layoutGroupItem.id,
                        name: layoutGroupItem.name,
                        sortOrder: layoutGroupItem.sortOrder,
                        showInNavigation: layoutGroupItem.showInNavigation
                    };
                })
        };

        return this._flexibleLayoutStorageGatewayService.saveSharedLayouts(args);
    }

    private _mapToLayoutGroupItem(
        layoutCatalogGroupResponse: LayoutCatalogGroupResponse
    ): LayoutGroupItem[] {
        const layouts = layoutCatalogGroupResponse.layouts.map(
            (layoutCatalogGroupDto: LayoutCatalogItemResponse) => {
                return {
                    id: layoutCatalogGroupDto.layoutId,
                    name: layoutCatalogGroupDto.name,
                    libraries: layoutCatalogGroupDto.libraries.join(", "),
                    dataSources: layoutCatalogGroupDto.dataSources,
                    sortOrder: layoutCatalogGroupDto.sortOrder,
                    showInNavigation: layoutCatalogGroupDto.showInNavigation,
                    groupId: layoutCatalogGroupDto.groupId
                };
            }
        );

        const groups = layoutCatalogGroupResponse.groups.map(
            (layoutCatalogGroupItemResponse: LayoutCatalogGroupItemResponse) => {
                return {
                    id: layoutCatalogGroupItemResponse.groupId,
                    name: layoutCatalogGroupItemResponse.groupName,
                    sortOrder: layoutCatalogGroupItemResponse.sortOrder
                };
            },
            []
        );

        return [...layouts, ...groups] as LayoutGroupItem[];
    }

    private _isGroupItem(layoutGroupItem: LayoutGroupItem): layoutGroupItem is LayoutGroupItem {
        return (
            (layoutGroupItem as LayoutGroupItem).dataSources === undefined &&
            (layoutGroupItem as LayoutGroupItem).libraries === undefined
        );
    }
}
