<lgflex-form-section-column>
    <lgflex-form-row [label]="'.WidgetName' | lgTranslate">
        <div class="input-with-help-info">
            <input
                class="input input--wide"
                type="text"
                [value]="widgetName"
                (change)="widgetNameChange.emit($any($event.target).value)"
                [required]="true"
            />
            <lg-help-info
                header="{{ '.WidgetName' | lgTranslate }}"
                description="{{
                    '_Flexible.ConfigurationDialogCommon.WidgetNameHelpInfo' | lgTranslate
                }}"
            ></lg-help-info>
        </div>
    </lgflex-form-row>
    <lgflex-form-row [label]="'.WidgetDescription' | lgTranslate">
        <textarea
            [value]="widgetDescription"
            (change)="widgetDescriptionChange.emit($any($event.target).value)"
            [required]="false"
            rows="3"
            style="min-height: 70px"
        ></textarea>
    </lgflex-form-row>
</lgflex-form-section-column>
<lgflex-form-section-column>
    <lgflex-form-row [label]="'.WidgetTooltip' | lgTranslate">
        <textarea
            [value]="widgetTooltip"
            (change)="widgetTooltipChange.emit($any($event.target).value)"
            [required]="false"
            rows="3"
            style="min-height: 70px"
        ></textarea>
    </lgflex-form-row>
    <lgflex-form-row
        *ngIf="_canEditWidgetTooltipLink | async"
        [label]="'.WidgetTooltipLink' | lgTranslate"
    >
        <input
            class="input input--wide"
            type="text"
            [value]="widgetTooltipLink"
            (change)="widgetTooltipLinkChange.emit($any($event.target).value)"
        />
    </lgflex-form-row>
</lgflex-form-section-column>
