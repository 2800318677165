<ng-container *ngIf="!standalone">
    <ng-container *lgPanelHeader="let context; order: -1">
        <div
            *ngIf="this._selectedKeys.length > 0"
            class="lg-reference-dropdown-side-icon lg-reference-dropdown-side-icon--round-corners"
        >
            <lg-icon icon="icon-go-back" (click)="_drilldownGoUp()" [clickable]="true"></lg-icon>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="_isModelBuilt">
    <lg-col-definition #colDefinition columnClass="table__column">
        <lg-col-row id="headerRow">
            <lg-col
                *ngFor="let col of _headerColumnDef"
                [id]="col.id"
                [class]="col.columnCls"
                [width]="col.width"
                [type]="col.type"
            ></lg-col>
        </lg-col-row>

        <lg-col-row id="footerRow">
            <lg-col
                *ngFor="let col of _totalsColumnDef"
                [id]="col.id"
                [class]="col.columnCls"
                [width]="col.width"
                [type]="col.type"
            ></lg-col>
        </lg-col-row>

        <lg-col-row id="midHeader">
            <lg-col id="__name"></lg-col>
        </lg-col-row>

        <ng-container *ngFor="let level of _levels; let i = index">
            <ng-container *ngFor="let subLevel of level.columnDefinition; let j = index">
                <lg-col-row [id]="'level_' + i + '_' + j">
                    <lg-col
                        *ngFor="let col of subLevel.columns"
                        [id]="col.id"
                        [type]="col.type"
                        [class]="col.columnCls"
                        [width]="col.width"
                    ></lg-col>
                </lg-col-row>
            </ng-container>
        </ng-container>
    </lg-col-definition>

    <ng-container *ngIf="_showPivot$ | async">
        <lg-rectangle class="flexcol" #pivotWrapper [auditTime]="0">
            <ng-container *ngFor="let level of _visibleLevels; let i = index">
                <ng-container *ngIf="i === 0">
                    <div
                        class="table__row table__row--header"
                        [lgColDefinition]="colDefinition"
                        [lgColDefinitionWidth]="pivotWrapper.width + 6"
                        lgColRow="headerRow"
                    >
                        <ng-container *ngFor="let headerCell of _headerRow">
                            <lg-pivot-table-body-level-headers
                                *ngIf="headerCell.type === 'levels'"
                                [body]="pivotBody"
                                [sortBy]="level.pivot.orderBy"
                                (sortByChange)="_onLevelsOrderByChange(level, $event)"
                                [lgCol]="headerCell.id"
                                [levels]="headerCell.levelHeaders"
                            >
                            </lg-pivot-table-body-level-headers>

                            <div
                                class="table__column table__column-group"
                                *ngIf="headerCell.type === 'group'"
                                [lgCol]="headerCell.id"
                                [lgColSpan]="headerCell.colspan"
                            >
                                <div class="table__column-group__top">
                                    <ng-container
                                        *ngIf="
                                            _getReference(headerCell.referenceIdx)?.isLocked;
                                            else referenceNotLocked
                                        "
                                    >
                                        <span>
                                            {{
                                                _getReference(headerCell.referenceIdx)?.name ??
                                                    (_getReference(headerCell.referenceIdx)
                                                        ?.nameLc ?? "" | lgTranslate)
                                            }}
                                        </span>
                                    </ng-container>

                                    <ng-template #referenceNotLocked>
                                        <lg-reference-dropdown
                                            class="lg-reference-dropdown lg-reference-dropdown__pivot-header crop"
                                            popupClassName="lg-reference-dropdown-popup"
                                            [definition]="_referencesDropdown"
                                            [current]="_getReference(headerCell.referenceIdx)?.code"
                                            (currentChange)="
                                                _onReferenceSelected(
                                                    headerCell.referenceIdx,
                                                    $event
                                                )
                                            "
                                            align="center"
                                            [isHeader]="true"
                                            [matchWidth]="headerCell.children.length > 1"
                                            [hideSearch]="true"
                                            [itemTooltips]="true"
                                        ></lg-reference-dropdown>
                                    </ng-template>
                                </div>

                                <div class="table__column-group__bottom">
                                    <ng-container *ngFor="let child of headerCell.children">
                                        <ng-container *ngIf="child.type === 'default'">
                                            <div
                                                [lgCol]="child.id"
                                                [lgSortByColumn]="child.orderBy"
                                                [sortBy]="
                                                    _maxVisibleLevel!.pivot.orderBy[
                                                        _maxVisibleLevel!.maxVisibleSubLevel
                                                    ]
                                                "
                                                (sortByChange)="_onOrderByChange(child.id)"
                                            >
                                                <div
                                                    class="flexpivot-field-number"
                                                    *ngIf="child.fieldNumber != null"
                                                >
                                                    <span class="flexpivot-field-number__circle">
                                                        {{ child.fieldNumber }}
                                                    </span>
                                                </div>

                                                <span
                                                    class="crop-text"
                                                    *ngIf="child.header != null"
                                                    style="width: fit-content"
                                                >
                                                    {{ child.header }}
                                                </span>

                                                <span
                                                    class="crop-text"
                                                    *ngIf="child.header == null"
                                                    style="width: fit-content"
                                                    lgTranslate
                                                >
                                                    {{ child.headerLc }}
                                                </span>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <lgflex-flexible-drilldown-table-header-row
                                [headerCell]="headerCell"
                                [sortBy]="
                                    _maxVisibleLevel!.pivot.orderBy[
                                        _maxVisibleLevel!.maxVisibleSubLevel
                                    ]
                                "
                                [pageReferences]="_pageReferences"
                                (orderByChange)="_onOrderByChange($event)"
                                (differenceColumnChange)="differenceColumnChange.emit($event)"
                            ></lgflex-flexible-drilldown-table-header-row>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="i !== 0">
                    <div
                        class="table__row table__row--midheader"
                        [lgColDefinition]="colDefinition"
                        [lgColDefinitionWidth]="pivotWrapper.width - 10"
                        lgColRow="midHeader"
                    >
                        <lg-pivot-table-body-level-headers
                            [body]="pivotBody"
                            [sortBy]="level.pivot.orderBy"
                            (sortByChange)="_onLevelsOrderByChange(level, $event)"
                            lgCol="__name"
                            [levels]="level.levelHeaders"
                        >
                        </lg-pivot-table-body-level-headers>
                    </div>
                </ng-container>

                <lg-pivot-table-body
                    #pivotBody
                    [definition]="level.pivot.definition"
                    [filteredData]="level.pivot.filtered"
                    (clearAllFilters)="_onClearAllFilters(filters)"
                    [itemHeight]="28"
                    [lgColDefinition]="colDefinition"
                    [lgColDefinitionWidth]="pivotWrapper.width - 10"
                    (maxVisibleLevelChange)="_onMaxVisibleSubLevelChange(i, $event)"
                    [class.flex-flexible]="_maxVisibleLevelIdx === i"
                    [class.table--collapsed]="_maxVisibleLevelIdx !== i"
                >
                    <ng-container
                        *ngFor="let subLevel of level.subLevels; let j = index; index as rowIndex"
                    >
                        <lg-pivot-table-row
                            *lgPivotTableRowDef="let row; onLevel: subLevel.levelId; items as items"
                            [toggleOnClick]="subLevel.openOnClick && _maxVisibleLevelIdx === i"
                            (click)="_drillDown(i, j, row)"
                            [class.table__row--disabled]="row.$hidden"
                        >
                            <div
                                class="table__row"
                                [class.table__row--clickable]="!subLevel.isVeryLast"
                                [lgColRow]="'level_' + i + '_' + j"
                            >
                                <ng-container *ngFor="let cell of subLevel.cells">
                                    <lg-pivot-table-empty-column
                                        *ngIf="cell.type == 'empty'"
                                        [lgCol]="cell.id"
                                        [level]="0"
                                    ></lg-pivot-table-empty-column>

                                    <lg-pivot-table-expand
                                        *ngIf="cell.type == 'expand'"
                                        [lgCol]="cell.id"
                                    ></lg-pivot-table-expand>

                                    <lgflex-flexible-drilldown-table-row-content
                                        lgflexConditionalFormatting
                                        [conditionalFormattingDisabled]="
                                            cell.conditionalFormatting !== true ||
                                            i !== _visibleLevels.length - 1
                                        "
                                        [conditionalFormattingLevel]="levels[i].at(-1)"
                                        [conditionalFormattingItemId]="
                                            cell.type === 'default' ? cell.fieldName : undefined
                                        "
                                        [conditionalFormattingValue]="
                                            cell.type === 'default'
                                                ? row[cell.fieldName]
                                                : undefined
                                        "
                                        [cell]="cell"
                                        [levels]="levels[i]"
                                        [selectedKeys]="_selectedKeys"
                                        [rowIndex]="rowIndex + 1"
                                        [row]="row"
                                        [filters]="filters"
                                        [scheme]="scheme"
                                        [items]="items"
                                        [maxColumnValues]="_maxColumnValues"
                                    ></lgflex-flexible-drilldown-table-row-content>
                                </ng-container>
                            </div>
                        </lg-pivot-table-row>
                    </ng-container>
                </lg-pivot-table-body>

                <lg-pivot-instance-sync
                    [component]="pivotBody"
                    [pivotInstance]="level.pivot"
                ></lg-pivot-instance-sync>

                <lgflex-flexible-drilldown-table-footer
                    *ngIf="_maxVisibleLevelIdx === i"
                    [colDefinition]="colDefinition"
                    [colDefinitionWidth]="pivotWrapper.width - 10"
                    [totalsRow]="_totalsRow"
                    [pivot]="level.pivot"
                >
                </lgflex-flexible-drilldown-table-footer>
            </ng-container>
        </lg-rectangle>
    </ng-container>
</ng-container>
