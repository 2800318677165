import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { LgNavigationService } from "@logex/framework/lg-application";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { FlexibleLayoutStorageService } from "../../services/flexible-layout-storage/flexible-layout-storage.service";
import { FlexibleAnalyticsService } from "../../services/flexible-analytics/flexible-analytics.service";

@Component({
    selector: "lgflex-breadcrumb",
    templateUrl: "./flexible-breadcrumb.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexibleBreadcrumbComponent {
    private _navigationService = inject(LgNavigationService);
    private _flexibleLayoutStorageService = inject(FlexibleLayoutStorageService);
    private _service = inject(FlexibleAnalyticsService);

    @Input() homeId: string | null = null;

    @Input() toolNameLc = "APP.ToolName";

    @Input() titlePrefixLc: string | null = null;

    @Input() toolNameParams: Record<string, any> | null = {};

    _state$ = combineLatest([
        this._flexibleLayoutStorageService._catalogLayouts$,
        this._navigationService.currentNodePath$()
    ]).pipe(
        map(([layouts, navNode]) => {
            const state = [];
            if (layouts.length > 0 && this._service.layoutId) {
                const currentLayout = layouts.find(l => l.id === this._service.layoutId);

                if (
                    currentLayout.showInNavigation === undefined ||
                    currentLayout.showInNavigation === false
                ) {
                    const group = layouts.find(l => l.id === currentLayout.groupId);
                    if (group) {
                        state.push({ id: group.id, name: group.name });
                    }

                    state.push({ id: currentLayout.id, name: currentLayout.name });
                }
            }
            return state;
        })
    );
}
