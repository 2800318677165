import { PageReferencesService } from "../services/page-references/page-references.service";
import {
    FieldInfo,
    FORMAT_PRECISION_MAX,
    FORMAT_PRECISION_MIN,
    PivotTableColumn,
    PivotTableColumnDefault,
    PivotTableColumnDifference,
    PivotTableColumnFormula,
    PivotTableColumnFormulaInput,
    PivotTableLevel
} from "../types";
import * as _ from "lodash-es";
import { WidgetTypesRegistry } from "../services/widget-types-registry";
import { Injector } from "@angular/core";
import { getConfiguratorInstance } from "./configurator-helpers";
import { Dictionary } from "lodash";
import { ColumnWidget } from "../types/configuration";

const MAX_LIMIT_ROWS_COUNT = 100;

export function validateChartColumns(
    columns: PivotTableColumn[],
    pageReferences: PageReferencesService,
    allowedFields: FieldInfo[]
): boolean {
    if (!columns || columns.length === 0) return false;

    if (
        !validateColumnOrdering(
            columns.filter(x => x.isEnabled),
            allowedFields
        )
    )
        return false;

    for (const x of columns) {
        if (x.type === "default" && x.isEnabled) {
            if (!validateDefaultColumn(x, pageReferences, allowedFields)) return false;
        }

        if (
            x.type === "difference" &&
            x.isEnabled &&
            !validateDifferenceColumn(x, pageReferences, allowedFields)
        ) {
            return false;
        }

        if (
            x.type === "formula" &&
            x.isEnabled &&
            !validateFormulaColumn(x, pageReferences, allowedFields)
        ) {
            return false;
        }

        if (x.type === "formula" && x.formatType === "percentage" && x.formatPrecision % 1 !== 0) {
            return false;
        }
    }

    return true;
}

export function validateTableColumns(
    columns: PivotTableColumn[],
    pageReferences: PageReferencesService,
    allowedFields: FieldInfo[]
): boolean {
    if (!columns) return true;

    if (
        !validateColumnOrdering(
            columns.filter(x => x.isEnabled),
            allowedFields
        )
    )
        return false;

    for (const x of columns) {
        if (
            x.type === "default" &&
            x.isEnabled &&
            (!validateDefaultColumn(x, pageReferences, allowedFields) ||
                !validateValueColumnWidth(x.width))
        ) {
            return false;
        }

        if (
            x.type === "difference" &&
            x.isEnabled &&
            !validateDifferenceColumn(x, pageReferences, allowedFields)
        ) {
            return false;
        }

        if (
            x.type === "formula" &&
            x.isEnabled &&
            !validateFormulaColumn(x, pageReferences, allowedFields)
        ) {
            return false;
        }

        if (x.type === "formula" && x.formatType === "percentage" && x.formatPrecision % 1 !== 0) {
            return false;
        }
    }

    return true;
}

export function validateWidgets(
    parentInjector: Injector,
    columns: PivotTableColumn[],
    widgetTypes: WidgetTypesRegistry,
    pageReferencesService: PageReferencesService
): boolean {
    for (const x of columns) {
        // Widget column
        if (x.type === "widget" && widgetTypes.tryGet(x.widgetType) == null) return false;

        // Visualization widget
        if (x.visualizationWidget !== undefined) {
            const widgetType = widgetTypes.tryGet(x.visualizationWidget.type);
            if (widgetType == null) return false;
            const configurator = getConfiguratorInstance(
                parentInjector,
                widgetType,
                pageReferencesService
            );
            if (configurator !== undefined && !configurator.validate(x.visualizationWidget.config))
                return false;
        }
    }

    return true;
}

export function validateColumnReferences(
    slotsIndexes: number[],
    pageReferences: PageReferencesService
): boolean {
    if (slotsIndexes.length === 0) return false;

    const slotsCount = Math.max(...slotsIndexes);
    return slotsCount <= pageReferences.slots.length - 1;
}

export function validateDefaultColumn(
    column: PivotTableColumnDefault,
    pageReferences: PageReferencesService,
    scheme: FieldInfo[]
): boolean {
    return (
        scheme.some(field => field.field === column.field) && // Validate if selected field exists in scheme
        (!pageReferences.isAllowed() ||
            (column.referenceIdx != null &&
                validateColumnReferences([column.referenceIdx], pageReferences)))
    );
}

export function validateColumnOrdering(columns: PivotTableColumn[], scheme: FieldInfo[]) {
    const seenReferences = new Set<number>();
    let prevReference: number | undefined = undefined;
    for (const column of columns) {
        if (column.type !== "default") continue;

        const field = _.find(scheme, x => x.field === column.field);

        if (field?.isReferenceBound) {
            if (!_.isNumber(column.referenceIdx)) {
                // Column references a reference-bound field, but doesn't have "referenceIdx" set.
                return false;
            }

            // Check that all reference-bound fields are grouped together
            if (prevReference !== column.referenceIdx) {
                if (seenReferences.has(column.referenceIdx)) {
                    // There are more than one group of fields bound to reference
                    return false;
                }
                seenReferences.add(column.referenceIdx);
            }
            prevReference = column.referenceIdx;
        } else {
            if (column.referenceIdx !== undefined) {
                // Field is not bound to a reference, so column cannot have "referenceIdx" set.
                return false;
            }

            prevReference = undefined;
        }
    }
    return true;
}

export function validateDifferenceColumn(
    column: PivotTableColumnDifference,
    pageReferences: PageReferencesService,
    allowedFields: FieldInfo[]
): boolean {
    if (!pageReferences.isAllowed()) {
        return false;
    }

    return (
        column.mode != null &&
        column.referenceLeft != null &&
        column.referenceRight != null &&
        column.field != null &&
        allowedFields.some(field => field.field === column.field) &&
        validateColumnReferences([column.referenceLeft, column.referenceRight], pageReferences)
    );
}

export function validateFormulaColumn(
    column: PivotTableColumnFormula,
    pageReferences: PageReferencesService,
    allowedFields: FieldInfo[]
): boolean {
    return (
        column.formula != null &&
        column.formatType != null &&
        column.formatPrecision >= FORMAT_PRECISION_MIN &&
        column.formatPrecision <= FORMAT_PRECISION_MAX &&
        column.variables != null &&
        Object.values(column.variables).length > 0 &&
        Object.values(column.variables).every(formulaVar =>
            isFormulaVariableFieldValid(formulaVar, pageReferences, allowedFields)
        )
    );
}

export function validateWidgetColumn(column: ColumnWidget): boolean {
    return column.widgetType != null;
}

export function isFormulaVariableFieldValid(
    formulaVar: PivotTableColumnFormulaInput,
    pageReferences: PageReferencesService,
    allowedFields: FieldInfo[]
): boolean {
    if (formulaVar.type === "constant") {
        return formulaVar.constant != null;
    }

    if (!formulaVar.field) return false;

    const fieldInfo = allowedFields.find(field => field.field === formulaVar.field);
    return (
        fieldInfo != null &&
        ((fieldInfo.isReferenceBound &&
            formulaVar.reference != null &&
            validateColumnReferences([formulaVar.reference], pageReferences)) ||
            !fieldInfo.isReferenceBound)
    );
}

export function validateTitle(title: string | undefined | null): boolean {
    return title != null && title.length > 0;
}

export function validateDescription(description: string | null | undefined): boolean {
    return true;
}

export function validateDimensions(tablesConfig: any[], scheme?: FieldInfo[]): boolean {
    if (tablesConfig.length === 0) {
        return false;
    }

    return tablesConfig.every(tablesConfigElement => {
        if (!validateTableSorting(tablesConfigElement)) {
            return false;
        }

        if (!validateTableLimitRows(tablesConfigElement)) {
            return false;
        }

        if (tablesConfigElement.dimensions.length === 0) {
            return false;
        }

        if (tablesConfigElement.orderBy && !validateOrderBy(tablesConfigElement.orderBy)) {
            return false;
        }

        const isDimensionInvalid = tablesConfigElement.dimensions.some(
            dimension => !validateDimensionSorting(dimension)
        );

        const isStackBarDimensionInvalid = !validateStackedBarDimension(
            tablesConfigElement.stackedBarDimension,
            scheme
        );

        return !(isDimensionInvalid || isStackBarDimensionInvalid);
    });
}

export function validateTableSorting(table): boolean {
    return table.limitRows > 0
        ? !table.orderBy.some(sorting => !sorting.item || !sorting.type)
        : true;
}

export function validateTableLimitRows(table): boolean {
    if (table.limitRows === 0 || table.limitRows === undefined || table.limitRows === 10000) {
        return true;
    } else {
        return table.limitRows > 0 && table.limitRows <= MAX_LIMIT_ROWS_COUNT;
    }
}

export function validateDimensionSorting(dimension): boolean {
    return dimension.overrideFromParent
        ? !!(dimension.orderBy.item && dimension.orderBy.type)
        : true;
}

function validateOrderBy(orderBy: any[]): boolean {
    return orderBy.every(oderByItem => !!oderByItem.item && !!oderByItem.type);
}

export function validateStackedBarDimension(
    dimension: string | undefined,
    scheme: FieldInfo[]
): boolean {
    return (
        dimension === undefined ||
        scheme.some(item => item.field === dimension && !item.isValueField)
    );
}

export function validateLevels(
    levels: Set<string>,
    scheme: FieldInfo[],
    levelProperties?: Dictionary<PivotTableLevel>
): boolean {
    if (levels.size === 0) return false;
    for (const level of levels.values()) {
        if (!scheme.some(item => item.field === level)) {
            return false;
        }
    }

    if (levelProperties) {
        for (const key in levelProperties) {
            if (!validateDimensionColumnWidth(levelProperties[key].width)) {
                return false;
            }
        }
    }

    return true;
}

export function validateDimensionColumnWidth(value: number | string | undefined): boolean {
    return value == null || validateWidth(value);
}

export function validateValueColumnWidth(value: number | string | undefined): boolean {
    return value != null && validateWidth(value);
}

function validateWidth(value: number | string): boolean {
    const MAX_WIDTH = 400;
    return Number(value) <= MAX_WIDTH && Number(value) >= 0;
}
