import { Component, input, output } from "@angular/core";
import {
    FdpColumnHeader,
    FdpColumnSimpleHeaderGroup,
    FdpDifferenceColumnChangeEvent
} from "../../../types";
import { PageReferencesService } from "../../../services/page-references/page-references.service";

@Component({
    selector: "lgflex-simple-group",
    templateUrl: "./simple-group.component.html"
})
export class SimpleGroupComponent {
    headerCell = input<FdpColumnSimpleHeaderGroup>();
    columnSortBy = input<string | null>();
    pageReferences = input<PageReferencesService>();
    // --------------------------------------------------
    onOrderByChange = output<string>();
    onDifferenceColumnChange = output<FdpDifferenceColumnChangeEvent>();
}
