import { provideWidgetMigration } from "../../../services/widget-types-registry";

import { PIVOT_TABLE_WIDGET_2 } from "../pivot-table-widget.configuration.types";

import { migrateVersion3To4 } from "./v3-v4";
import { migrateVersion4To5 } from "./v4-v5";
import { migrateVersion5To6 } from "./v5-v6";

export const pivotTableMigrationProvider = provideWidgetMigration(PIVOT_TABLE_WIDGET_2, {
    3: migrateVersion3To4,
    4: migrateVersion4To5,
    5: migrateVersion5To6
});
